<template>
  <div class="web__main" >
    <template v-for="(msgWrapperItem,index) in msgList">
      <!-- <p>{{getMsg(msgWrapperItem.msg.fromUserId)}}</p> -->
      <!-- {{msgWrapperItem.msg.msgBody.type}} -->
      <p class="msg-time" :key="index+'l'" v-if="(index%5 == 0&&index!=0)?true:false" >{{getTimeFormat(msgWrapperItem.msg.msgCdt)}}</p>
      <div class="main__content" :class="msgWrapperItem.msg.msgBody.type+(getMsg(msgWrapperItem.msg.fromUserId)?' msgMy':'')"  :key="index">
        <msg-element-revoke
          :id="'msg'+msgWrapperItem.msg.msgId"
          v-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='revoke'"
          :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
          :msg-wrapper="msgWrapperItem"
          :index="index"
          :key="index"
          :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
        />
        <msg-element-tip
          v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='tip'"
          :id="'msg'+msgWrapperItem.msg.msgId"
          :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
          :msg-wrapper="msgWrapperItem"
          :index="index"
          :key="index"
          :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
        />
        <msg-element-session-ask-patient 
          v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='session_ask_patient'"
          :id="'msg'+msgWrapperItem.msg.msgId"
          :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
          :msg-wrapper="msgWrapperItem"
          :index="index"
          :key="index"
          :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
        />
        <msg-element-to-artificial-msg
          v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='msg_toArtificial'"
          :id="'msg'+msgWrapperItem.msg.msgId"
          :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
          :msg-wrapper="msgWrapperItem"
          :index="index"
          :key="index"
          :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
        />
        <el-popover
          v-else
          :placement="getMsg(msgWrapperItem.msg.fromUserId)?'left':'right'"
          :key="index+'a'"
          
          trigger="manual"
          :popper-class="getMsg(msgWrapperItem.msg.fromUserId)?'myImPopover popoverLeft':'myImPopover popoverRight'"
          v-model="msgWrapperItem.showPopover">
          <div class="pop-btn">
            <el-button v-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='text'" @click="copyText(msgWrapperItem.msg.msgBody.content.text,index)" type="text">{{$t("im.copy")}}</el-button>
            <el-button v-if="msgWrapperItem.withdrawShow" @click="msgWithdraw(index)" type="text">{{$t("im.withdraw")}}</el-button>
          </div>
          <div slot="reference" @touchstart="gotouchstart(index)" @touchmove="gotouchmove" @touchend="gotouchend" @blur="closePopover(msgWrapperItem,index)">
            <msg-element-image
              v-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='image'"
              :id="'msg'+msgWrapperItem.msg.msgId"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid '+myId"
              :msg-wrapper="msgWrapperItem"
              :index="msgWrapperItem.msg.msgId"
              :key="index"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
              :on-click-img="onClickImg"
            />
            
            <msg-element-video
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='video'"
              :id="'msg'+msgWrapperItem.msg.msgId"
              :msg-wrapper="msgWrapperItem"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :index="index"
              :key="index"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
            <msg-element-text
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='text'"
              :id="'msg'+msgWrapperItem.msg.msgId"
              :msg-wrapper="msgWrapperItem"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :index="index"
              :key="index"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
            
            <msg-element-common-questions
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='common_questions'"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
              @clickProblem="clickProblem"
            />
            
            <msg-element-keywords 
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='keywords'"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
              @msgArtificial="msgArtificial"
            />

            <template v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='session_score_notice'">
              <msg-element-evaluate
                v-if="msgWrapperItem.msg.msgHasPlayed === 0"
                :msg-wrapper="msgWrapperItem"
                :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
                :index="index"
                :key="index"
                :chatId="chatId"
              />
            </template>

            <msg-element-question
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='question'"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />

            <msg-element-seesion-score
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='session_score'"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
            <template
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='session_close_request'"
            >
              <msg-element-over
                v-if="msgWrapperItem.msg.msgHasPlayed === 0"
                :msg-wrapper="msgWrapperItem"
                :index="index"
                :key="index"
                :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
                :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
                :chatId="chatId"
              />
            </template>
            <msg-element-business-card
              :id="'msg'+msgWrapperItem.msg.msgId"
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='usercard'"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
              <msg-element-share
              :id="'msg'+msgWrapperItem.msg.msgId"
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='share'"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
            <msg-element-audio
              :id="'msg'+msgWrapperItem.msg.msgId"
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='sound'"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :key="index"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
            <msg-element-patient
              v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='patient_card'"
              :key="index"
              :id="'msg'+msgWrapperItem.msg.msgId"
              :index="index"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :msg-wrapper="msgWrapperItem"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
      <!--      -->
      <!--      <Msg-element-card-->
      <!--        v-else-->
      <!--        :id="'msg'+msgWrapperItem.msg.msgId"-->
      <!--        :msg-wrapper="msgWrapperItem"-->
      <!--        :index="index"-->
      <!--        :is-my-msg="msgWrapperItem.msg.fromUserId===myId"-->
      <!--      >-->
      <!---->
      <!--      </Msg-element-card>-->
            <template v-else-if="msgWrapperItem.msg.msgBody && msgWrapperItem.msg.msgBody.type==='article'">
              <Msg-element-article
                :id="'msg'+msgWrapperItem.msg.msgId"
                :msg-wrapper="msgWrapperItem"
                :index="index"
                :key="index"
                :is-my-msg="msgWrapperItem.msg.fromUserId===myId"
              >
              </Msg-element-article>
            </template>

            <msg-element-unknown
              v-else
              :key="index"
              :id="'msg'+msgWrapperItem.msg.msgId"
              :msg-wrapper="msgWrapperItem"
              :index="index"
              :data-userid="msgWrapperItem.msg.fromUserId+'--yonghuid'+myId"
              :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
            />
          </div>
        </el-popover>
      </div>

    </template>
  </div>
</template>
<script>
import MsgElementText from './msg/MsgElementText.vue'
import MsgElementImage from './msg/MsgElementImage.vue'
import MsgElementVideo from './msg/MsgElementVideo.vue'
import MsgElementUnknown from './msg/MsgElementUnknown.vue'
import MsgElementTip from './msg/MsgElementTip.vue'
import MsgElementArticle from './msg/MsgElementArticle.vue'
import MsgElementCommonQuestions from './msg/MsgElementCommonQuestions.vue'
import MsgElementEvaluate from './msg/MsgElementEvaluate.vue'
import MsgElementQuestion from './msg/MsgElementQuestion.vue'
import MsgElementSeesionScore from './msg/MsgElementSeesionScore.vue'
import MsgElementOver from './msg/MsgElementOver.vue'
import MsgElementShare from './msg/MsgElementShare.vue'
import MsgElementRevoke from './msg/MsgElementRevoke.vue'
import MsgElementPatient from "./msg/MsgElementPatient.vue";
import { ImagePreview } from 'vant'
import { buildCommonQuest, newSendingMsg, toMsgWrapper } from '../imapi'
import MsgElementBusinessCard from './msg/MsgElementBusinessCard.vue'
import MsgElementAudio from './msg/MsgElementAudio.vue'

import press from "@/assets/js/press.js"
import MsgElementCard from './msg/MsgElementCard.vue'
import MsgElementSessionAskPatient from './msg/MsgElementSessionAskPatient.vue'
import MsgElementKeywords from './msg/MsgElementKeywords.vue'
import MsgElementToArtificialMsg from "./msg/MsgElementToArtificialMsg.vue"
export default {
  directives:{
    press
  },
  components: {
    MsgElementText,
    MsgElementImage,
    MsgElementVideo,
    MsgElementUnknown,
    MsgElementTip,
    MsgElementCard,
    MsgElementArticle,
    MsgElementOver,
    MsgElementQuestion,
    MsgElementCommonQuestions,
    MsgElementEvaluate,
    MsgElementSeesionScore,
    MsgElementShare,
    MsgElementBusinessCard,
    MsgElementAudio,
    MsgElementRevoke,
    MsgElementPatient,
    MsgElementSessionAskPatient,
    MsgElementKeywords,
    MsgElementToArtificialMsg
  },
  props: {
    msgList: { type: Array},
    chatId: { chatId: Number }
  },
  data() {
    return {
      timeOutEvent:0,
      myId: this.$store.getters.id,
      myName: this.$store.getters.name,
      myFaceUrl: this.$store.getters.avatar,
      imgList:[],
      imgSeeData:[]
    }
  },
  created() {
    // console.log(this.msgList);
//    this.chatId = Number(this.$route.query.chatId)
      this.msgList.forEach(item=>{
        if(item.msg.msgBody.type == 'image'){
          this.imgList.push({url:item.msg.msgBody.content.big.url,id:item.msg.msgId})
          this.imgSeeData.push(item.msg.msgBody.content.big.url)
        }
      })
  },
  watch: {
    msgList:{
      handler(newValue,oldvalue){
        this.imgList = [];
        this.imgSeeData = [];
        for(let it in this.msgList){
          let item = this.msgList[it]
          if(item.msg.msgBody.type == 'image'){
            // let res = this.imgList.find(img => img == item.msg.msgBody.content.big.url)
            if(item.msg.msgBody.content.big.url.indexOf("data:image")==-1){
              this.imgList.push({url:item.msg.msgBody.content.big.url,id:item.msg.msgId})
              this.imgSeeData.push(item.msg.msgBody.content.big.url)
            }
          }
        }
        // console.log(newImg);
        // for(let g in newImg){
        //   this.imgList.unshift(newImg[g])
        // }
      },
      deep:true
    },
  },
  methods: {
    onClickImg(index) {
      let cindex = this.imgList.findIndex(itemA => itemA.id == index);
      // console.log(cindex);
      this.previewImage(cindex);
    },
    // 预览图片
    previewImage(index) {
      ImagePreview({
        images: this.imgSeeData,
        // showIndex:false,
        loop:false,
        startPosition: index
      })
    },
    // 发送 转人工消息
    msgArtificial(){
      // :is-my-msg="getMsg(msgWrapperItem.msg.fromUserId)"
      let res = this.msgList.find(itme => !this.getMsg(itme.msg.fromUserId))
      res.msg.msgBody.type = 'msg_toArtificial';
      let arrObj = this.msgList[this.msgList.length-1];
      if(arrObj.msg.msgBody.type != 'msg_toArtificial'){
        this.msgList.push(res)
      }
    },
    closePopover(item,index){
      setTimeout(() => {
        // alert("21313")
        // this.msgList[index].showPopover = false
      }, 100);
    },
    rightClick(){
      console.log("123");
    },
    handleClickLong(index){
      // console.log("erwrw");
      if(this.getMsg(this.msgList[index].msg.fromUserId)){
        // let   this.msgList[index].msgCdt
        // console.log((new Date().getTime() - 2 *60 * 1000) < this.msgList[index].msgCdt,(new Date().getTime() - 2 *60 * 1000) , this.msgList[index].msg.msgCdt);
        if(this.msgList[index].msg.msgBody.type==='text'){
          this.msgList[index].showPopover = true;
        }else{
          this.msgList[index].showPopover = false;
        }
        if((new Date().getTime() - 2 *60 * 1000) < this.msgList[index].msg.msgCdt){
          this.msgList[index].withdrawShow = true;
          this.msgList[index].showPopover = true;
        }else{
          this.msgList[index].withdrawShow = false;
        }
      }
    },
    gotouchstart(index){
      let that = this;
      for(let g in that.msgList){
        that.msgList[g].showPopover = false;
      }
      clearTimeout(this.timeOutEvent);//
      this.timeOutEvent = 0;
      this.timeOutEvent = setTimeout(function(){
            //，
            // this.msgList[index].showPopover = true;
          if(that.getMsg(that.msgList[index].msg.fromUserId)){
            // let   that.msgList[index].msgCdt
            if((new Date().getTime() - 2 *60 * 1000) < that.msgList[index].msg.msgCdt){
              that.msgList[index].withdrawShow = true;
              that.msgList[index].showPopover = true;
            }else if(that.msgList[index].msg.msgBody.type==='text'){
              that.msgList[index].showPopover = true;
              that.msgList[index].withdrawShow = false;
            }else{
              that.msgList[index].showPopover = false;
              that.msgList[index].withdrawShow = false;
            }
          // console.log((new Date().getTime() - 2 *60 * 1000) < that.msgList[index].msgCdt,(new Date().getTime() - 2 *60 * 1000) , that.msgList[index].msg.msgCdt);
          }else if(that.msgList[index].msg.msgBody.type==='text'){
                that.msgList[index].showPopover = true;
          }
        },600);//
    },
          //，500，，onclick
    gotouchend(){
        clearTimeout(this.timeOutEvent);
          if(this.timeOutEvent!=0){
            //（onclick）
        }
    },
    //，，
    gotouchmove(){
        clearTimeout(this.timeOutEvent);//
        this.timeOutEvent = 0;
    },
    getTimeFormat(times){
      let str;
      let date = new Date(times);
      let num = new Date(new Date().toLocaleDateString()).getTime();
      // console.log(num);
      if(times>num){
        if(date.getHours()>12){
          str = ' '+(date.getHours() - 12>9?date.getHours()-12:'0'+(date.getHours()-12))+":"+(date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes());
        }else{
          str = ' '+(date.getHours()>9?date.getHours():'0'+date.getHours())+":"+(date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes());
        }
      }else if((num-24*60*60*1000)<times){
        if(date.getHours()>12){
          str = this.$t("im.day")+' '+this.$t("im.afternoon")+(date.getHours() - 12>9?date.getHours()-12:'0'+(date.getHours()-12))+":"+(date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes());
        }else{
          str = this.$t("im.yesterday")+' '+this.$t("im.forenoon")+(date.getHours()>9?date.getHours():'0'+date.getHours())+":"+(date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes());
        }
      }else{
        str = date.getFullYear()+'-'+((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+'-'+(date.getDate()>9?date.getDate():'0'+date.getDate())+"  "+(date.getHours()>9?date.getHours():'0'+date.getHours())+":"+(date.getMinutes()>9?date.getMinutes():'0'+date.getMinutes());;
      }
      return str;
    },
    // childOnHidden(index){
    //   this.msgList.splice(index,1);
    // },
    async clickProblem(problemData){
      // console.log(problemData);
      var msgBody = buildCommonQuest(problemData)
      var msgSummary = problemData.question
      var msgItem = toMsgWrapper(
        newSendingMsg(this.chatId,
          msgBody, msgSummary, this.myId, this.myName, this.myFaceUrl
        )
      )
      msgItem.sending = true
      this.msgList.push(msgItem)
      this.$parent.$parent.getScollrchBottom();
      // console.log(msgItem);
      // return;
//      alert(this.chatId)
      const resData = await this.$imapiAxio.post(
        '/im/msg-send',
        {
          chatId: this.chatId,
          msgBody: msgBody,
          msgSummary: msgSummary,
          sessionEnv: {}
        }
      )
      Object.assign(msgItem.msg, resData.data)
      msgItem.sending = false;
    },
    copyText(txt,index) {
        var oInput = document.createElement('input');     //创建一个隐藏input
        oInput.value = txt;    //赋值
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display='none';
        oInput.remove();
        this.msgList[index].showPopover = false;
        this.$toast(this.$t("comonTxt.copysuccess"))
    },
    async msgWithdraw(index){
//       /im/msg-revoke
      const result = await this.$imapiAxio.post(
        '/im/msg-revoke',
        {
          userMsgId: this.msgList[index].msg.userMsgId,
        }
      );
      // alert(JSON.stringify(result))
      if(result.errorCode == 0){
        this.msgList[index].msg.msgBody.type = "revoke";
        this.msgList[index].showPopover = false;
      }
    },
    
    getMsg(fromUserId){
      if(fromUserId==this.myId){
        return true
      }else{
        return false
      }
    }
  }
}
</script>

<style scoped lang="less">
.web__main {
  padding: 0.5rem;
  padding-bottom: 2rem;
  .msg-time{
    font-size: 0.48rem;
    line-height: 0.98rem;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    margin-top: 1.4rem;
    color: #A8A8AB;
    width: 100%;
    text-align: center;
  }
}
</style>
<style lang="less">
@import "./msg/msgType.less";
.session_score_notice{
  .el-popover__reference-wrapper{
    justify-content: center !important;
    max-width: 100% !important;
    margin:  0 auto;
  }
}
.msgMy{
    &>span{
      justify-content: flex-end !important;
      &>.el-popover__reference-wrapper{
        justify-content: flex-end !important;
      }
    }
    .web__main-item{
      // max-width: ;
      box-sizing: border-box;
      max-width: 100% !important;
    }
}
.main__content {
  width: 100%;
  &>span{
    display: flex;
    justify-content: flex-start;
    // flex: 1;
    width: 100%;
    .el-popover__reference{
      width: max-content;
      overflow: hidden;
    }
    &>.el-popover__reference-wrapper{
      max-width: 85%;
      width: max-content;
      float: right;
      width: -webkit-fill-available;
      display: flex;
      justify-content: flex-start;
      // width: max-content;
      // max-width: 75%;
      .web__main-item--mine{
        float: right;
        width: max-content;
        // max-width: 65%;
      }
      .web__main-item{
        max-width: unset;
      }
    }
  }
  
}
.popoverLeft{
  transform: translate(0.5rem,0);

}
.popoverRight{
  transform: translate(-0.5rem,0);
}
.myImPopover{
  padding: 0 0.4rem;
  min-width: max-content;
  // margin-top: 2.2rem;
  .pop-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .el-button {
      margin-left: 0;
      color: #606266;
      span{
        font-size: 0.6rem;
      }
    } 
  }
}
</style>
