<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       
      <!-- <cite>
        {{msgWrapper}}
        00000
      </cite> -->
    </div>
    <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text">
      <div class="web__main-arrow"  />
      {{msgWrapper.msg.sending}}
      <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
        <p class="text">
            {{$t("im.msgArtrificialtxt")}}
            <a @click="toArtificialFun">{{$t("im.toArtificial")}}</a>
        </p>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      showPopover:false
    }
  },
  props: {
    isMyMsg: {
      type: Boolean,
      default: false,
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    onResend: { type: Function }

  },
  mounted () {
    // console.log(this.isMyMsg)
  },
  methods: {
        toArtificialFun(){
            this.$parent.$parent.$parent.toArtificial();
        }
  }
}
</script>

<style lang="scss" scoped>
.web__main-text{
  // margin-bottom: 1rem;
  .text{
    a{
        color: #49A7FF;
    }
  }
}
</style>