<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       
      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text">
      <div class="web__main-arrow" />
      <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
      <!-- <template v-for="(item, index) in msgWrapper.msg.msgBody.content.contents"> -->
        <msg-element-text-text v-if="msgWrapper.msg.msgBody.type==='question'" :msg-wrapper="msgWrapper" :text="msgWrapper.msg.msgBody.content.question" />
      <!-- </template> -->
    </div>
  </div>
</template>
<script>
import MsgElementText_Text from './MsgElementText_Text.vue'

export default {
  components: {
    MsgElementTextText: MsgElementText_Text
  },
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
  },
  created(){
    // console.log(this.msgWrapper.msg);
  }
}
</script>

