
<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       

      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text main-over">
        <div class="web__main-arrow" />
        <div class="web_mian-businesssCard" @click="goPath(msgWrapper.msg.msgBody.content.userId)">
            <div class="businesssCard-box">
                <img :src="msgWrapper.msg.msgBody.content.userFaceUrl || require('@/assets/img/column-icon3.png')" >
                <p class="businesssCard-name">{{msgWrapper.msg.msgBody.content.userName}}</p>
            </div>
            <p class="title-text">{{msgWrapper.msg.fromUserName}}{{$t("im.recommendDoctor")}}</p>
        </div>
    </div>
  </div>
</template>
<script>

export default {
    props: {
        isMyMsg: {
        type: Boolean,
        default: false
        },
        msgWrapper: { type: Object },
        index: { type: Number },
        chatId: { chatId: Number }
    },
    data(){
        return{
        }
    },
    created(){
    },
    methods:{
        goPath(doctorId){
            this.$router.push(`/staff/detail/${doctorId}`)
        }
    }
}
</script>

<style lang="scss" scoped>
    /deep/ .main-over{
        padding: 0;
        max-width: max-content;
        .web_mian-businesssCard{
            padding: 0 .6rem;
            .businesssCard-box{
                display: flex;
                padding: .5rem 0;
                align-items: center;
                border-bottom: 0.05rem solid #E5E5E5;
                img{
                    width: 1.64rem;
                    height: 1.64rem;
                    margin-right: .6rem;
                }
                .businesssCard-name{
                    font-size: .64rem;
                    font-weight: 500;
                    color: #333333;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    margin: unset;
                }
            }
            .title-text{
                font-size: .44rem;
                font-weight: 400;
                color: #838385;
                width: max-content;
            }
        }
    }
</style>
