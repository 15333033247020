<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       
      <!-- <cite>
        {{msgWrapper}}
        00000
      </cite> -->
    </div>
    <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text">
      <div class="web__main-arrow"  />
      {{msgWrapper.msg.sending}}
      <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
          <template v-for="(item, index) in msgWrapper.msg.msgBody.content.contents">
            <msg-element-text-text :key="index" v-if="item.type==='text'" :msg-wrapper="msgWrapper" :index="index" :text="item.value" />
            <msg-element-text-face
              :key="index+'1'"
              v-else-if="item.type==='face'"
              :msg-wrapper="msgWrapper"
              :index="index"
              :face="item.value"
            />
            <msg-element-text-at v-else-if="item.type==='at'" :key="index+'q'" :msg-wrapper="msgWrapper" :index="index" :at="item.value" />
          </template>
    </div>
  </div>
</template>
<script>
import MsgElementText_At from './MsgElementText_At.vue'
import MsgElementText_Face from './MsgElementText_Face.vue'
import MsgElementText_Text from './MsgElementText_Text.vue'

export default {
  components: {
    MsgElementTextAt: MsgElementText_At,
    MsgElementTextFace: MsgElementText_Face,
    MsgElementTextText: MsgElementText_Text
  },
  data(){
    return{
      showPopover:false
    }
  },
  props: {
    isMyMsg: {
      type: Boolean,
      default: false,
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    onResend: { type: Function }

  },
  mounted () {
    // console.log(this.isMyMsg)
  },
  methods: {
    setContent(data) {
      let str
      data.forEach(item => {
        str += item.value
      })
      return str
    }
  }
}
</script>

<style lang="scss" scoped>
.web__main-text{
  // margin-bottom: 1rem;
}
</style>