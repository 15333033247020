<template>
  <div>
    <p class="tip-text">
      {{ msgWrapper.msg.msgBody.content.text }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number }
  },
  created(){
    console.log(this.msgWrapper);
    if(this.msgWrapper.msg.chatName){
      document.title = this.msgWrapper.msg.chatName
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-text{
  font-size: 0.48rem;
  line-height: 0.96rem;
  text-align: center;
  font-weight: 400;
  color: #A8A8AB;
  width: 100%;
  margin: 1rem auto;
}
</style>