
<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       

      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>

     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text main-over">
        <div class="web__main-arrow" />
        <div class="web_mian-audio"  >
            <i class="oneplay" v-if="msgWrapper.msg.msgHasPlayed == 0"></i>
            <i class="after" @click="goPlay"></i>
           <img :src="isMyMsg?(!playType?require('@/assets/img/myaudio-play-icon.png'):require('@/assets/img/myaudio-stop-icon.png')):playType?require('@/assets/img/youraudio-stop-icon.png'):require('@/assets/img/youraudio-play-icon.png')">
           <div id="waveform" ref="waveform"></div>
           <span class="time" :class="{'wirtime':isMyMsg}">{{msgWrapper.msg.msgBody.content.len}}"</span>
        </div>
    </div>
  </div>
</template>
<script>
import WaveSurfer from "wavesurfer.js";
export default {
    props: {
        isMyMsg: {
            type: Boolean,
            default: false
        },
        msgWrapper: { type: Object },
        index: { type: Number },
        chatId: { chatId: Number }
    },
    data(){
        return{
            wavesurfer: null,
            playType:false,
        }
    },
    mounted(){
        this.$nextTick(() => {
        // console.log(this.$refs);
          this.wavesurfer = WaveSurfer.create({
            container: this.$refs.waveform,
            waveColor:!this.isMyMsg?'#3A3A3A':'#7fc1ff',
            barWidth: 1,
            cursorColor:"#7fc1ff",
            progressColor:this.isMyMsg?"#fff":'#7fc1ff',
            backend:"MediaElement",
            // mediaControls: false,
            audioRate:1,
            //
          });
          // ：require()，
          this.wavesurfer.load(this.msgWrapper.msg.msgBody.content.url);
        });
    },
    methods:{
        goPlay(){
            if(!this.playType&&this.$store.getters.audioPlayType){
                this.$store.dispatch("video/setPlayType",{type:false,playId:this.msgWrapper.msg.msgId})
                this.wavesurfer.playPause.bind(this.wavesurfer)();
                if(this.msgWrapper.msg.msgHasPlayed == 0){
                    this.msgWrapper.msg.msgHasPlayed = 1;
                    let datadd = {
                        userMsgId:this.msgWrapper.msg.msgId
                    }
                    let result =  this.$imapiAxio.post('/im/msg-play', datadd);
                }
                this.playType = true;
                this.wavesurfer.on('finish',()=>{
                    this.$store.dispatch("video/setPlayType",{type:true,playId:this.msgWrapper.msg.msgId})
                    this.playType = false;
                })
            }else{
                // if(!this.$store.getters.audioPlayType){
                //     if(this.wavesurfer.isPlaying()){
                //         this.wavesurfer.pause();
                //         this.$store.dispatch("video/setPlayType",{type:false,playId:this.msgWrapper.msg.msgId});
                //     }else{
                //         if(this.msgWrapper.msg.msgId == this.$store.getters.playId){
                //             this.wavesurfer.play();
                //         }else{
                //             this.$store.dispatch("video/setPlayType",{type:true,playId:this.msgWrapper.msg.msgId});
                //             this.goPlay();
                //         }
                //     }
                // }else{
                //     if(this.msgWrapper.msg.msgId == this.$store.getters.playId){
                //         this.wavesurfer.play();
                //     }
                //     console.log(";;;123",this.$store.getters);
                // }
                if(this.msgWrapper.msg.msgId == this.$store.getters.playId){
                    if(this.wavesurfer.isPlaying()){
                        this.wavesurfer.pause();
                        this.$store.dispatch("video/setPlayType",{type:true,playId:this.msgWrapper.msg.msgId});
                    }else{
                        this.wavesurfer.play();
                        this.$store.dispatch("video/setPlayType",{type:false,playId:this.msgWrapper.msg.msgId});
                    }
                }else{
                     if(this.$store.getters.audioPlayType){
                        this.wavesurfer.play();
                        this.$store.dispatch("video/setPlayType",{type:false,playId:this.msgWrapper.msg.msgId});
                        this.wavesurfer.on('finish',()=>{
                            this.$store.dispatch("video/setPlayType",{type:true,playId:this.msgWrapper.msg.msgId})
                            this.playType = false;
                        })
                    }
                }
            }
            // window.location.href = url;
        }
    }
}
</script>

<style lang="scss"  >
    .main-over{
        padding: 0;
        width: auto;
        max-width: 10rem;
        // .web__main-text{
        // }
        .web_mian-audio{
            position: relative;
            // padding: .5rem .4rem;
            display: flex;
            align-items: center;
            & .after{
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              display: block;
              left: 0;
              z-index: 9;
            }
            .oneplay{
                width: .3rem;
                height: .3rem;
                right: -.4rem;
                background: #F61818;
                border-radius: 50%;
                position: absolute;
                top: 0;
                display: block;
            }
            img{
                width:.72rem;
                height: .72rem;
                margin-right: .3rem;
                flex: 0 0 auto;
            }
            #waveform{
                flex: 1;
                min-width: 4rem;
                height: 1.2rem !important;
                wave{
                    height:1.2rem!important;
                    overflow: hidden !important;
                }
            }
            .time{
                flex: 0 0 auto;
                margin-left: .2rem;
                font-size: .56rem;
                font-weight: 400;
                color: #343434;
            }
            .wirtime{
                color: #fff;
            }
        }
    }
</style>
