<template>
    <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
        <div class="web__main-user">
            <img alt="" class="avator" :src="msgWrapper.msg.fromUserFaceUrl"/>
             
            <!-- <cite>
                {{ msgWrapper.msg.fromUserName }}
                <i>{{ msgWrapper.msg.msgId }}</i>
            </cite> -->
        </div>
         <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
        <div class="web__main-pro" >
            <div class="web_msg-comquestion-title">
                <p class="title-left">
                    <span>猜您想问</span>
                </p>
                <p class="msg-right" @click="moreProblem(2)" v-if="this.msgWrapper.msg.msgBody.content.keywords.length > pageSize">
                    <van-icon name="replay"> </van-icon>
                    <span class="">换一批</span>
                </p>
            </div>
            <div class="web_msg-problem_list">
                <div class="problem-item" v-for="(commonQuestItem,cindex) in question" :key="cindex" @click="ClickProblem(commonQuestItem)">
                    <p class="problem-title">
                        <span>{{commonQuestItem}}</span>
                        <img class="problem-right-icon" src="@/assets/images/problem-right-icon.png" >
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { throttle } from "@/utils/common";
import _ from 'lodash'
let that;
export default{
    props: {
        isMyMsg: {
            type: Boolean,
            default: false
        },

        msgWrapper: { type: Object },
        index: { type: Number },
    },
    data(){
        return{
            pageSize:4,
            pageNum:0,
            Pagetype:true,
            question:[],
            lastType:false
        }
    },
    created(){
        // console.log(this.msgWrapper.msg.msgBody.content.keywords.splice(0,3));
        let arr = this.$parent.$parent.$parent.$parent.msgList.filter((item) => {return item.msg.msgBody.type == "keywords"})
        let arrObj = arr.pop();
        if(arrObj.msg.msgId == this.msgWrapper.msg.msgId){
            this.lastType = true;
        }
        this.moreProblem(1)
        // if(this.msgWrapper.msg.msgBody.content.keywords.length<=this.pageSize){
        //     this.$emit("msgArtificial")
        // }
        // let arr = this.$parent.$parent.$parent.$parent.msgList.filter((item) => {return item.msg.msgBody.type == "keywords"})
        // console.log(arr.pop());        
        // console.log(this.index,this.$parent.$parent.$parent.$parent.msgList.length);
    },
    mounted(){
        that = this;
    },
    beforeDestroy(){
        that = null;
    },
    methods:{
        ClickProblem:_.throttle((problemData)=>{
            // console.log(problemData);
            // console.log("problemData",problemData,that);
            that.$parent.$parent.$parent.$parent.sendTextMsg(problemData,true);
            // that.$emit('clickProblem',{...problemData,msgSummary:that.msgWrapper.msg.msgSummary});
        },500),
        moreProblem(numType){
            if(!this.Pagetype){
                // return false;
            }
            this.pageNum++;
            let startNum = (this.pageNum-1)*this.pageSize,endNum = (this.pageNum)*this.pageSize;
            const arr = JSON.parse(JSON.stringify(this.msgWrapper.msg.msgBody.content.keywords));
            this.question = [];
            for (let i = 0; i < arr.length; i++) {
                const it = arr[i];
                if(i>=startNum && i<endNum){
                    this.question.push(it)
                }                
            }
            if(endNum>=arr.length){
                // console.log(toUserIsRobot&&toUserIsRobot);
                if(numType == 2){
                    this.$emit("msgArtificial")
                }else{
                    if(this.lastType){
                        this.$emit("msgArtificial")
                    }
                }
                // this.Pagetype = false;
                this.pageNum = 0;
                
            }
            this.$forceUpdate();
        }
        
    }
}
</script>
<style lang="scss" scoped>
 
    .web__main-item{
        // min-width: inherit;
        max-width: unset;
        overflow: hidden;
        .web__main-user{
            top: 1rem;
        }
        .web__main-pro{
            padding: 0.4rem 0.75rem;
            border-radius: .24rem;
            background: url("~@/assets/img/im-keywords-banner-back.png") no-repeat;
            background-color: #Fff;
            background-size:100% 1.8rem;
            background-position: 0 0;
            width: 8rem;
            &::after{
                content: " ";
                top: 1.2rem;
                left: 1.95rem;
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-color: transparent;
                border-style: solid;
                border-width: 0.35rem;
                border-left-width: 0;
                border-right-color: #DEEFFF;
            }
            .web_msg-comquestion-title{
                height: 0.48rem;
                line-height: 0.48rem;
                padding: .6rem 0;
                font-size: .72rem;
                font-family: PingFang SC;
                max-width: 100%;
                border-bottom: 0.05rem solid #ECEDEF;
                color: #333;
                font-weight: 600;
                position: relative;
                margin: initial;
                padding-top: .5rem;
                display: flex;
                justify-content: space-between;
                .icon{
                    position: absolute;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    margin-right: 0.15rem;
                    left: 0;
                    top: 50%;
                    transform: translate(0,-50%);
                }
                .msg-right{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    display: flex;
                    align-items: center;
                    margin-left: 0.2rem;
                    .van-icon {
                        color: #49A7FF;
                        font-weight: 600;
                    }
                }
            }
            .web_msg-problem_list{
                padding-bottom: 0.3rem;
                .problem-item{
                    padding:0.42rem  0;
                    font-size: 0.56rem;
                    line-height: 0.56rem;
                    border-bottom: 0.05rem solid #ECEDEF;
                    position: relative;
                    .problem-title{
                      font-size: 0.6rem;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #333333;
                      line-height: 0.8rem;
                      margin: inherit;
                      width: max-content;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      span{
                        margin-right: 0.2rem;
                      }
                    }
                    .problem-right-icon{
                        width: 0.3rem;
                        height: 0.4rem;
                       
                    }
                }
            }
        }
    }
    .web__main-text{
        // max-width: 80%;
    }
</style>
