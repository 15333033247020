<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--    <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--    <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl"/>
       

      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text">
      <div class="web__main-arrow"></div>
       <router-link to="">
      <div class="item-doctor-card">
        <div class="item-doctor-info">
          <img src="" alt="">
          <b>

          </b>
        </div>
        <p>

        </p>
      </div>
       </router-link>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    onClickImg: { type: Function },
    onResend: { type: Function }
  },
  data () {
    return {
      fileList: []
    }
  },
  mounted () {

    this.fileList = [this.msgWrapper.msg.msgBody.content.small]
    // console.log(this.fileList)
  },

}
</script>
<style lang="less" scoped>
.item-doctor-card{
  .item-doctor-info{
    padding-bottom:0.5rem;
   img{
     width:2rem;
     height:2rem;
     border-radius: 0.5rem;
     margin-right:0.25rem;
     border: 0.25rem solid red;
   }
  }
  p{
    font-size:0.6rem;
    color:#838385;
    border-top:0.05rem solid #E5E5E5;
    padding-top:0.25rem;
  }
}
</style>
