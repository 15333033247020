<template>
  <!--<div class="box" :contenteditable="isEdit" v-html="innerText" v-focus="editFocus"-->
  <!--<div>-->
  <div
    v-focus="editFocus"
    class="box"
    ref="boxs"
    :contenteditable="edit"
    style="-webkit-user-select:text"
    :placeholder="$t('im.consultTips')"
    @input="changeTxt"
    @focus="keyboard"
    @blur="lock=false"
    v-html="innerText"
  />
  <!--<div class="box" :contenteditable="edit" v-html="innerText" v-focus="editFocus" style="-webkit-user-select:text"-->
  <!--placeholder="......"-->
  <!--@input="changeTxt" @focus="lock=true"-->
  <!--@blur="lock=false"></div>-->
  <!--</div>-->

</template>
<script>
let type = true;
export default {
  //
  directives: {
    focus: {
      update(el, { value }) {
        if (value) {
          if(type){
              el.stopPropagation();
              return false;
          }
          if (window.getSelection) { // ie11 10 9 ff safari
            el.focus() // ff
            var range = window.getSelection()// range
            range.selectAllChildren(el)// range obj
            range.collapseToEnd()//
          } else if (document.selection) { // ie10 9 8 7 6 5
            var range = document.selection.createRange()//
            range.moveToElementText(el)// rangeobj
            range.collapse(true)//
            range.select()
          }
        } else {
          el.blur()
        }
      }
    }
  },
  props: {
    child: {
      type: Object,
      default: {
        html: ''
      }
    },
    isShowFaceList:{
      type:Boolean,
    },
    isShowMoreFunctions:{
      type:Boolean,
    },
    // isEdit:{
    //   type:Boolean,
    //   default:true
    // },
    editFocus: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerText: this.child.html,
      lock: false
    }
  },
  watch: {
    child: {
      handler(newValue, oldValue) {
        if (!this.lock) {
          this.innerText = this.child.html;
          // let txt = document
          // console.log();
          if(this.child.html==''&&this.$refs.boxs.innerHTML!=''){
            this.$refs.boxs.innerHTML  = '';
          }
          this.$emit(this.innerText,this.child.html)
          this.$forceUpdate();
        }
      },
      deep: true
    },
    isShowFaceList:{
      handler(){
        type = this.isShowFaceList || this.isShowMoreFunctions;
      }
    },
    isShowMoreFunctions:{
      handler(){
        type = this.isShowFaceList || this.isShowMoreFunctions;
      }
    }

  },
  methods: {
    keyboard() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        document.body.scrollTop = document.body.scrollHeight
      }
      this.lock = true;
    },
    changeTxt(e) {
      // console.log(this.$el.innerHTML);
      if (e == '') {
        this.$el.innerHTML = e
        this.child.html = e
      } else {
        this.child.html = this.$el.innerHTML
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
  .box{
    font-size: 0.6rem;
  -webkit-user-select:all;
  // width:65%;
  padding:0.28rem 0.5rem;

  // display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // height: 100%;
  max-height: 1.6rem;
  overflow: auto;
  outline-style:none;
  img{
    float: left;
  }

  *{
    -webkit-user-select:all
  }
}
.box:empty:before{
  content:attr(placeholder);
  color:#c7c7c7;
  padding-left:0.25rem;
}
.box:focus:before{
  content:none;
}
</style>
<style>
  .box img{
    max-width: 1rem !important;
  }
</style>
