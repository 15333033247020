import { render, staticRenderFns } from "./MsgElementToArtificialMsg.vue?vue&type=template&id=a08b9030&scoped=true"
import script from "./MsgElementToArtificialMsg.vue?vue&type=script&lang=js"
export * from "./MsgElementToArtificialMsg.vue?vue&type=script&lang=js"
import style0 from "./MsgElementToArtificialMsg.vue?vue&type=style&index=0&id=a08b9030&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a08b9030",
  null
  
)

export default component.exports