<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       
      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text main-over">
      <div class="web__main-arrow" />
      <p class="main-over-box">
          {{msgWrapper.msg.msgBody.content.title}}
          <span v-if="msgWrapper.msg.msgBody.content.subTitle">（{{msgWrapper.msg.msgBody.content.subTitle}}）</span>
      </p>
      <p class="over-btn" @click="overSession">{{$t('im.endSession')}}</p>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    chatId: { chatId: Number }
  },
  data(){
      return{
//        chatId: null,
      }
  },
  created(){
//      this.chatId = Number(this.$route.query.chatId);
  },
  methods:{
     async overSession(){
       console.log(this.$parent.$parent.$parent.$parent);
          this.$parent.$parent.$parent.$parent.msgList[this.index].msg.msgHasPlayed = 1;
          const resData = await this.$imapiAxio.post(
            '/im/session-close-confirm',
            {
                chatId: this.chatId,
                msgId:this.msgWrapper.msg.msgId,
            }
        )
        if(resData.errorCode == 0){
          this.$parent.$parent.$parent.$parent.toUserIsRobot = 1;
        }
      }
  }
}
</script>

<style lang="scss" scoped>
    .main-over{
        padding: 0;
        .main-over-box{
            padding: 0.4rem 0.75rem;
            font-size: 0.6rem;
            font-family: PingFang SC;
            box-sizing: border-box;
            font-weight: 400;
            color: #333333;
            line-height: 0.7rem;
            span{
                font-weight: 400;
                color: #999;
                font-size: 0.48rem;
            }
        }
        .over-btn{
            height: 1.4rem;
            border-top: 0.05rem solid #ECEDEF;
            line-height: 1.4rem;
            color: #49A7FF;
            font-size: 0.6rem;
            text-align: center;
            width: 100%;
        }
    }
</style>
