<template>
  <div class="web__main-score">
    <div class="main-score-title">
      <i></i>
      <p>{{msgWrapper.msg.msgBody.content.title}}</p>
      <i></i>
    </div>
    <div class="main-score-icon">
      <img :src="onIndex >= (index+1)?pitchOnImg:unImg" v-for="(item,index) in scoreList" :key="index" @click="onIndex = (index+1)">
    </div>

    <p v-if="onIndex!=null" class="main-score-text">{{scoreList[onIndex-1].value}}</p>
    <div class="score-quick-box">
        <p class="quich-text" :class="{'textActive':remarksText.indexOf(textItme)>-1}" v-for="(textItme,index) in msgWrapper.msg.msgBody.content.quickInputs" :key="index" @click="addRemarksText(textItme)">{{textItme}}</p>
    </div>
    <div class="score-remarks-box">
      <div class="remarks-text">
        <img class="icon" src="@/assets/images/evalute/edit-icon.png">
        <input type="text" class="remarks-ipt" v-model="remarksText" :placeholder="$t('im.appointmentDesc')" maxlength="100">
      </div>
      <p class="score-release-btn" :class="{'btnactive':onIndex!=null}" @click="submitScore">{{$t('im.sendAppointment')}}</p>
    </div>
  </div>
</template>
<script>
import { throttle } from "@/utils/common";
import _ from 'lodash'
import Vue from 'vue'
import { buildScoreMsgBody, newSendingMsg, toMsgWrapper } from '../../imapi'

let that;
export default{
  props:{
    msgWrapper: { type: Object },
    index: { type: Number },
    chatId: { chatId: Number }
  },
  data(){
    return{
//      chatId: null,
      myId: this.$store.getters.id || localStorage.getItem('userId'),
      myName: this.$store.getters.name,
      myFaceUrl: this.$store.getters.avatar,
      remarksText:'',
      //
      pitchOnImg:require("@/assets/images/evalute/select-evalute-icon.png"),
      //
      unImg:require("@/assets/images/evalute/evalute-icon.png"),
      //
      onIndex:null,
      scoreList:[
        {
          id:'1',
          value:"",
        },
        {
          id:'2',
          value:"",
        },
        {
          id:'3',
          value:"",
        },
        {
          id:'4',
          value:"",
        },
        {
          id:'5',
          value:"",
        }
      ]
    }
  },
  created(){
//    this.chatId = Number(this.$route.query.chatId);
  },
  updated(){
    that = this;
  },
  beforeDestroy(){
    that = null;
  },
  watch:{
    remarksText:{
      handler(newval,oldval){
        if(newval.length>100){
          this.remarksText = oldval;
        }
      }
    }
  },
  methods:{
    addRemarksText(str){
      if(this.remarksText.indexOf(str) > -1){
        let num = this.remarksText.indexOf(str);
        if(this.remarksText.length-str.length == num){
          this.remarksText = this.remarksText.split(','+str).join('');
        }else{
          this.remarksText = this.remarksText.split(str+',').join('');
        }
        if(this.remarksText.length==str.length){
          this.remarksText = '';
        }
      }else{
        if(this.remarksText.length==0){
          this.remarksText += str;
        }else{
          this.remarksText += (','+str);
        }
      }
    },
    //
    async submitScore(){
      that = this;
      if(that.onIndex==null){
        return;
      }
      // console.log(that.$parent.$parent.$parent.$parent.msgList[that.index].msg.msgHasPlayed );
      // that.msgWrapper.msg.msgHasPlayed = 1;
      console.log(that.$parent.$parent.$parent.$parent,that.index);
      let msg = {...that.$parent.$parent.$parent.$parent.msgList[that.index].msg};
      msg.msgHasPlayed = 1;
      // that.$parent.$parent.$parent.$parent.msgList[that.index].msg.msgHasPlayed = 1;
      Vue.set(
          that.$parent.$parent.$parent.$parent.msgList[that.index],
          "msg",
          msg
      );
      // that.$emit('childOnHidden',that.index);
      // console.log(that.$parent.$parent.$parent.$parent.msgList[that.index].msg,";;;;;");
      let arrItem = JSON.parse(JSON.stringify(that.$parent.$parent.$parent.$parent.msgList[that.index]));
      that.$parent.$parent.$parent.$parent.msgList.splice(that.index,1);

      // that.$parent.$parent.$parent.$parent.msgList = [];
      // that.$parent.$parent.$parent.$parent.msgList = arr
      that.$forceUpdate();
      var msgBody = buildScoreMsgBody({
        input:that.remarksText,
        score:that.onIndex,
        sessionId:that.msgWrapper.msg.msgBody.content.sessionId,
        title:that.msgWrapper.msg.msgBody.content.title,
      })

      var msgSummary = that.msgWrapper.msg.msgSummary;
      var msgItem = toMsgWrapper(
        newSendingMsg(that.chatId,
          msgBody, msgSummary, that.myId, that.myName, that.myFaceUrl
        )
      )
      // console.log(msgItem);
      msgItem.sending = true;
      that.$parent.$parent.$parent.$parent.msgList.push(msgItem);

      const resData = await that.$imapiAxio.post(
        '/im/session-score',
        {
          chatId: that.chatId,

          msgId:that.msgWrapper.msg.msgId,
          sessionId: that.msgWrapper.msg.msgBody.content.sessionId,
          score: that.onIndex,
          input:that.remarksText
        }
      )
      Object.assign(msgItem.msg, resData.data)
      msgItem.sending = false
    },
  }


}
</script>

<style lang="less" scoped>
/deep/  .el-popover__reference-wrapper{
  justify-content: center;
}
  .web__main-score{
    margin-bottom: 0.5rem;
    margin-top: 0.05rem;
    margin: 0 auto;
    .main-score-title{
      margin-top: 1.4rem;
      display: flex;
      p{
        font-size: 0.56rem;
        padding: 0 0.2rem;
        color: #838385;
        font-family: PingFang SC;
        font-weight: 400;
      }
      i{
        display: inline-block;
        flex: 1;
        border-top: 0.05rem solid #999999;
        margin-top: 0.36rem;
      }
    }
    .main-score-icon{
      width:9.64rem;
      margin: 0.6rem auto;
      height: 1rem;
      img{
        width: 1.16rem;
        height: 1rem;
        margin-left: 0.96rem;
        &:first-child{
          margin-left: 0;
        }
      }
    }
    .main-score-text{
      text-align: center;
      font-size: 0.6rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .score-quick-box{
      // display: flex;
      // flex-wrap: wrap;
      overflow: auto;
      margin-top: 0.25rem;
      
      .quich-text{
        float: left;
        font-size: 0.56rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #838385;
        padding: 0.15rem 0.3rem;
        min-width: 0.56rem;
        border-radius: 0 0.6rem;
        text-align: center;
        margin-right: 0.16rem;
        border: 0.05rem solid #838385;
        margin-bottom: 0.2rem;
        line-height: 0.57rem;
      }
      .textActive{
        border: 0.05rem solid #FF671C;
        color: #FF671C;
      }
    }
    .score-remarks-box{
      height: 1.44rem;
      margin-top: 0.28rem;
      padding: 0.06rem 0.1rem;
      border-radius: 0.77rem;
      background:#ECEEF3;
      display: flex;
      .remarks-text{
        flex: 1;
        padding-right: 0.2rem;
        padding-left: 1.5rem;
        position: relative;
        .icon{
          position: absolute;
          width: 0.6rem;
          height: 0.6rem;
          left: 0.34rem;
          top: 50%;
          margin-top: -0.3rem;
        }
        .remarks-ipt{
          width: 100%;
          height: 100%;
          line-height: 100%;
          font-size: 0.52rem;
          background: none;
          font-weight: 400;
          color: #838385;
        }
      }
      .score-release-btn{
        flex: 0 0 2.25rem;
        text-align: center;
        font-size: 0.52rem;
        font-weight: 500;
        margin: .08rem 0;
        margin-right: 0.2rem;
        border-radius: 1rem;
        color: #838385;
        padding: .2rem 0.6rem;
        line-height: 1rem;
        background: #C4C4C4;
      }
      .btnactive{
        color: #FFFFFF;
        background: #FF6519;
      }
    }
  }
</style>
