<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!-- <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>
    <div v-else-if="msgWrapper.sending">，</div> -->

    <div class="web__main-user">
      <img alt="" class="avator" :src="msgWrapper.msg.fromUserFaceUrl"/>
       
      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
    <div class="web__main-text web__main-transition">
        <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
        <span class="item_msg" @click="goPlay">
              <video class="web_msg--upload" :id="'video'+msgWrapper.msg.msgCdt" v-if="msgWrapper.sending" controls="controls"></video>
              <img
                v-else
                class="web__msg--video"
                :src="msgWrapper.msg.msgBody.content.smallImg.url"
              />
             <img src="@/assets/images/video-play-icon.png" class="web__msg--video-play" />
        </span>
    </div>
    <!-- <van-overlay :show="show" @click="show = false" z-index="111111111111" >

    </van-overlay> -->
    <van-popup v-model="show"  get-container="body" class="videoPopup">

        <img src="@/assets/images/warning.png" class="close" @click="back">
        <div  class="video-box" v-if="msgWrapper.msg.msgBody&&msgWrapper.msg.msgBody.content.smallImg">
          <video  ref="video" id="video"   controls="controls" @focus="clickVideo($event)" :poster="msgWrapper.msg.msgBody.content.smallImg.url">
              <source :src="msgWrapper.msg.msgBody.content.url" type="video/mp4">
              <!-- <source :src="msgWrapper.msg.msgBody.content.url" type="video/webm">
              <source :src="msgWrapper.msg.msgBody.content.url" type="video/ogg"> -->
              {{$t('im.noVideo')}}
          </video>
        </div>
    </van-popup>
    <!-- <van-overlay :show="show" @click="show = false"  get-container="body" >
      <div class="wrapper" @click.stop>
        <div class="block" />
      </div>
    </van-overlay> -->
    <!--<van-popup v-model="show">
       <video
        :src="src"
        style="object-fit: cover;"
        controls="controls"
        :closeable="true"
        :close-on-click-overlay="false"
        get-container="body"
      ></video>
    </van-popup>-->
  </div>

</template>
<script>
export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    onClickImg: { type: Function },
    onResend: { type: Function }
  },
  data () {
    return {
      src: 'https://www.w3school.com.cn/i/movie.mp4',
      show:false
    }
  },
  mounted () {

  },
  methods:{
    goPlay(){
      this.show = true;
      setTimeout(()=>{
        // console.log(this.$refs.video);
        this.$refs.video.play();
        // window.scrollBy(0,1);
      // window.location.href = this.msgWrapper.msg.msgBody.content.url
      },500)
      // this.$refs.video.play();
    },
    clickVideo(){
      e.preventDefault();
    },
    back(){
      this.show = false;
      this.$refs.video.pause();
      this.$refs.video.currentTime=0;
    }
  }
}
</script>
<style lang="less" scoped>
.item_msg {
  padding: 0;
  margin: 0;
  display: inline-block;
  display: flex;
  position: relative;
  .web__msg--img,
  .web__msg--video,
  .web_msg--upload,
  .web__msg--file {
    max-height: 6.4rem;
    max-width: 3.6rem;
    min-width: 4rem;
    // width: 100%;
    margin: 0.5rem 0;
    border: 0.05rem solid #eee;
    overflow: hidden;
    border-radius: 0.25rem;
    cursor: pointer;
    display: block;
  }
  .web__msg--video{
    min-width: auto;
  }
  .web__main-transition .sendLoad {
    left: -30%;
  }
  .web__msg--img[data-class="iconBox"] {
    max-width: 1.2rem;
    min-width: unset;
    border: none;
    margin: 0;
    vertical-align: bottom;
    display: inline-block;
  }

  .web__msg--video-play {
    display: inline-block;
    width: 0.86rem;
    height: 0.86rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-size: cover;
    position: absolute;
  }
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.2rem;
  color: #fff;
  font-size: 0.6rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.videoPopup{
  width: 90%;
  height: 95%;
  margin: 0 auto;
  background: none;
  img{
    width: 1.25rem;
    height: 1.25rem;
    right: 0;
    top: 0;
    z-index: 9;
    position: absolute;
  }
  .video-box{
    width: 100%;
    height: 100%;
    // margin-top: 1.5rem;
    video{
      height: 100%;
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
}
</style>
