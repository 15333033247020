<template>
  <img alt="" :src="faceMap[face]">
</template>
<script>
import {
  faceMap1 as _faceMap
} from '../../faceData'

export default {
  props: {
    msgWrapper: { type: Object },
    index: { type: Number },
    face: { type: String }
  },
  data () {
    return {
      faceMap: _faceMap
    }
  },
  created () {
    console.log(this.faceMap);
  }
}
</script>
<style lang="less" scoped>
img {
  width: 1rem;
  height: 1rem;
  margin-left: 0.15rem;
}
</style>
