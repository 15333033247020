<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl"/>
       
    </div>
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text">
      <div class="web__main-arrow"></div>
      <router-link :to="'/article/detail?materialId=' + msgWrapper.msg.msgBody.content.id">
        <div class="item-doctor-article">
          <div class="item-article-info">
            <b>
              {{msgWrapper.msg.msgBody.content.title}}
            </b>
            <img :src="msgWrapper.msg.msgBody.content.coverUrl" alt="">
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    onClickImg: { type: Function },
    onResend: { type: Function }
  },
  data () {
    return {
      fileList: []
    }
  },
  mounted () {

    this.fileList = [this.msgWrapper.msg.msgBody.content.small]
    // console.log(this.fileList)
  },

}
</script>
<style lang="less" scoped>
.item-doctor-article{
  .item-article-info{
    padding-bottom:0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img{
      width:2rem;
      height:2rem;
      flex: 0 0 auto;
      border-radius: 0.5rem;
      margin-right:0.25rem;
    }
    b{
      font-size:0.6rem;
      color:#838385;
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-weight: normal;
      margin-right:0.5rem;
    }
  }
  p{
    padding-bottom:0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
