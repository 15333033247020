import { faceMap1 } from './faceData'

var msgWrapperKey = 0

export function newSendingMsg(chatId, msgBody, msgSummary, myId, myName, myFaceUrl) {
    return {
        chatId: chatId,
        msgCdt: new Date().getTime(),
        msgSummary: msgSummary,
        msgBody: msgBody,
        msgHasRead: 1,
        msgHasPlayed: 1,
        fromUserId: myId,
        fromUserName: myName,
        fromUserSnRemark: '',
        fromUserRegRole: 0,
        fromUserFaceUrl: myFaceUrl
    }
}

export function toMsgWrapper(msg) {
    msgWrapperKey++
    return {
        key: msgWrapperKey,
        msg: msg,
        sending: false,
        popShow: false,
        sendError: ''
    }
}

export function toMsgWrappers(msgArray) {
    var array = []
    if (msgArray) {
        msgArray.forEach((item, index) => {
            array.push(toMsgWrapper(item))
        })
    }
    return array
}

export function buildTextMsgBody(msgContent) {
    var contents = []
    var tmsg, restMsgIndex
    var originContent = msgContent
    var expr = /\[[^[\]]{1,4}\]/mg
    var emotions = msgContent.match(expr)
    if (!emotions || emotions.length < 1) {
        contents.push({
            'type': 'text',
            'value': msgContent
        })
    } else {
        for (var i = 0; i < emotions.length; i++) {
            tmsg = msgContent.substring(0, msgContent.indexOf(emotions[i]))
            if (tmsg) {
                contents.push({
                    'type': 'text',
                    'value': tmsg
                })
            }

            if (faceMap1[emotions[i]]) {
                contents.push({
                    'type': 'face',
                    'value': emotions[i]
                })
            } else {
                contents.push({
                    'type': 'text',
                    'value': emotions[i]
                })
            }
            restMsgIndex = msgContent.indexOf(emotions[i]) + emotions[i].length
            msgContent = msgContent.substring(restMsgIndex)
        }
        if (msgContent) {
            contents.push({
                'type': 'text',
                'value': msgContent
            })
        }
    }

    return {
        'content': {
            'contents': contents,
            'text': originContent
        },
        'type': 'text'
    }
}

export function buildScoreMsgBody(msgContent) {
    return {
        "content": {
            "input": msgContent.input,
            "score": msgContent.score,
            "sessionId": msgContent.sessionId,
            "title": "我的评价"
        },
        "type": "session_score"
    }
}

export function buildPatientBody(data){
    return{
        "type": "patient_card",
        "content": {...data,userId:data.id}
    }
}

export function buildRecordMsgBody(Recordata) {
    return {
        'content': {
            'len': Recordata.len,
            // 'localPath': Recordata.localPath,
            'url': Recordata.url,
            // 'localId': Recordata.localId
        },
        'type': 'sound'
    }
}



export function buildVideoMsgBody(imageBase64) {

    // return false;
    return {
        'content': {
            'bigImg': {
                'height': '',
                'url': '',
                'width': ''
            },
            'smallImg': {
                'height': '',
                'url': '',
                'width': ''
            },
            url: imageBase64
        },
        'type': 'video'
    }
}
export function buildImgMsgBody(imageBase64) {
    const img = new Image()
    img.src = imageBase64

    return {
        'content': {
            'big': {
                'height': img.height,
                'url': imageBase64,
                'width': img.width
            },
            'small': {
                'height': img.height,
                'url': imageBase64,
                'width': img.width
            }
        },
        'type': 'image'
    }
}
// {"content":{"question":"abc","questionId":0},"type":"question"}
export function buildCommonQuest(msgContent) {
    return {
        "content": {
            "question": msgContent.question,
            "questionId": msgContent.questionId
        },
        "type": "question"
    }
}

export function formatText2Html(text) {
    var html = text
    if (html) {
        html = xssFilter(html) // 用户昵称或群名称等字段会出现脚本字符串
        html = html.replace(/ /g, '&nbsp;')
        html = html.replace(/\n/g, '<br/>')
    }
    return html
}

// 防止XSS攻击
function xssFilter(val) {
    val = val.toString()
    val = val.replace(/[<]/g, '&lt;')
    val = val.replace(/[>]/g, '&gt;')
    val = val.replace(/"/g, '&quot;')
        // val = val.replace(/'/g, "&#039;");
    return val
}
