<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--    <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--    <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator"   :src="msgWrapper.msg.fromUserFaceUrl"/>
       
      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text web__main-transition">
        <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
        <span class="item_msg">
          <!-- <van-uploader v-if="!msgWrapper.sending" max-count="1" class="myvanuploader" v-model="fileList" :disabled="true" :deletable="false"/> -->
          <img :src="item.url"  v-for="(item,cindex) in fileList" :key="cindex" @click="onClickImg(index)">
          <template v-if="msgWrapper.msg.msgBody.content.tags&&msgWrapper.msg.msgBody.content.tags.length!=0">
            <div class="img-type"  >
              <p class="tag" v-for="(item,cindex) in msgWrapper.msg.msgBody.content.tags" :key="cindex">{{item}}</p>
            </div>
          </template>
        </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    // 消息id  用于寻找 图片 所在消息
    index: { type: Number },
    onClickImg: { type: Function },
    onResend: { type: Function }
  },
  data () {
    return {
      fileList: []
    }
  },
  watch:{
    msgWrapper:{
      deep:true,
      handler:function(){
        this.fileList = [this.msgWrapper.msg.msgBody.content.big]
      }
    }
  },
  mounted () {
    this.fileList = [this.msgWrapper.msg.msgBody.content.big]
  },

}
</script>
<style lang="less" >
.item_msg {
  padding: 0;
  margin: 0;
  display: block;
  width: max-content;
  height: max-content;
  overflow: hidden;
  max-width: 100%;
  // margin-right: 10%;
  float: right;
  max-height: 10rem;
  &>img{
      height: auto;
      width: auto;
      max-width: 100% !important;
      border-radius: 0.32rem;
      max-height: 10rem;
  }
  .img-type{
    margin-top: 0.1rem;
    .tag{
      margin: 0 0.2rem 0.1rem 0;
      width: max-content;
      padding: 0.1rem 0.2rem;
      background: #ccc;
      font-size: 0.4rem;
      line-height: initial;
      float: left;
      border-radius: 0.16rem;
      color: #fff;
    }
  }
  div{
    width: 100%;
  }
  .web__msg--img,
  .web__msg--video,
  .web__msg--file {
    max-width: 12.5rem;
    min-width: 2.5rem;
    width: 100%;
    margin: 0.5rem 0;
    border: 0.05rem solid #eee;
    overflow: hidden;
    border-radius: 0.25rem;
    cursor: pointer;
    display: block;
  }

  .web__msg--img[data-class="iconBox"] {
    max-width: 1.2rem;
    min-width: unset;
    border: none;
    margin: 0;
    vertical-align: bottom;
    display: inline-block;
  }
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.2rem;
  color: #fff;
  font-size: 0.6rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
</style>
<style lang="scss">
  .item_msg{

    // img{
    //   width: 100%;
    //   height: 100%;
    // }
    .van-uploader__preview{
      margin: 0;
      width: 100%;
    }
    .van-uploader__preview-image{
      // display: block;
      width: max-content;
      height: max-content;
      max-width: 100%;
      max-height: 10rem;
      // overflow: hidden;
      // max-width: 80%;
      // margin-right: 10%;
      // float: right;
      img{
        height: auto;
        width: auto;
        max-width: 100% !important;
        border-radius: 0.32rem;
        max-height: 10rem;
      }
    }
    .van-uploader{
      .van-uploader__wrapper--disabled{
        opacity: 1;
      }
    }
  }
</style>
