<template>
    <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
        <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
        <!--      <div v-else-if="msgWrapper.sending">，</div>-->
        <div class="web__main-user">
            <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
             
        <!-- <cite>
            {{ msgWrapper.msg.fromUserName }}
            <i>{{ msgWrapper.msg.msgId }}</i>
        </cite> -->
        </div>
         <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
        <div class="web__main-text main-myScore">
            <div class="web__main-arrow" />
            <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
            <span class="score-title">{{msgWrapper.msg.msgBody.content.title}}</span>
            <span class="score-text" v-if="msgWrapper.msg.msgBody.content.input">{{msgWrapper.msg.msgBody.content.input}}</span>
            <div class="score-icon-box">
                <!-- scoreList -->
                <img :src="onIndex >= (index+1)?pitchOnImg:unImg" v-for="(item,index) in scoreList" :key="index">
            </div>
        </div>
    </div>
</template>
<script>

export default {

  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
  },
  data(){
      return{
            //
        unImg:require("@/assets/images/evalute/evalute-result-onicon.png"),
        //
        pitchOnImg:require("@/assets/images/evalute/evalute-result-icon.png"),
           scoreList:[
                {
                    id:'1',
                    value:"",
                },
                {
                    id:'2',
                    value:"",
                },
                {
                    id:'3',
                    value:"",
                },
                {
                    id:'4',
                    value:"",
                },
                {
                    id:'5',
                    value:"",
                }
            ],
            onIndex:null
      }
  },
  created(){
    //   console.log(this.msgWrapper.msg.msgBody);
      this.onIndex = this.msgWrapper.msg.msgBody.content.score;
  }
}
</script>

<style lang="scss" scoped>
    .main-myScore{
        max-width: 100%;
        .score-title{
            padding-left: 0.5rem;
            display: block;
            font-size: 0.6rem;
            border-left: 0.12rem solid #B6DAFD;
            height: 0.6rem;
            line-height: 0.6rem;
            margin-bottom: 0.2rem;
        }
        .score-text{
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 0.55rem;
            color: #FFFFFF;

        }
        .score-icon-box{
            display: flex;
            width: 6.8rem;
            height: 0.8rem;
            /*margin: 0 auto;*/
            padding-top: 0.25rem;
            justify-content:space-between;
            img{
                width: 0.92rem;
                height: 0.8rem;
            }
        }
    }
</style>
