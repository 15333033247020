<template>
  <div class="revoke">
      <p class="" v-if="isMyMsg">{{$t("im.msgwithdraw")}}</p>
      <p v-else>{{msgWrapper.msg.msgBody.content.text}}</p>
  </div>
</template>

<script>
export default {
    props: {
        isMyMsg: {
            type: Boolean,
            default: false
        },
        msgWrapper: { type: Object },
        index: { type: Number },
        onClickImg: { type: Function },
        onResend: { type: Function }
    },
}
</script>

<style lang="scss" scoped>
.revoke{
    font-size: 0.48rem;
    line-height: 0.98rem;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 400;
    margin-top: 1.4rem;
    color: #A8A8AB;
}
</style>
