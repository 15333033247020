<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       
      <!-- <cite>
        {{msgWrapper}}
        00000
      </cite> -->
    </div>
    <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text">
        <div class="web__main-arrow"  />
        {{msgWrapper.msg.sending}}
        <van-loading size="30" class="sendLoad" v-if="isMyMsg&&msgWrapper.sending" />
        <p class="txtd">{{$t("im.sendPatientPlacegholder")}}</p>
        
    </div>
    <div class="selecBtn" @click="selectPatient">
        <i class="icon"></i>
        <span>{{$t("im.selectPatientBtn")}}</span>
    </div>
  </div>
</template>
<script>
export default {

    data(){
        return{
        showPopover:false
        }
    },
    props: {
        isMyMsg: {
        type: Boolean,
        default: false,
        },
        msgWrapper: { type: Object },
        index: { type: Number },
        onResend: { type: Function }

    },
    mounted () {
        // console.log(this.isMyMsg)
    },
    methods: {
        selectPatient() {
            this.$parent.$parent.$parent.sendPatient();
        }
    }
}
</script>

<style lang="scss" scoped>
.txtd{
    font-size: 0.56rem;
}
.web__main-item{
    
}
.selecBtn{
    height: max-content;
    padding: 0.4rem 0.6rem;
    display: flex;
    align-items: center;
    width: max-content;
    background: linear-gradient(0deg, #298AFB 0%, #80D3FF 100%);
    border-radius: 1rem;
    margin-top: 0.4rem;
    .icon{
        width: 0.64rem;
        height: .72rem;
        background: url("~@/assets/img/im-selectPatient-icon.png") no-repeat;
        background-size: 100% 100%;
    }
    span{
        display: block;
        width: max-content;
        height: max-content;
        font-size: 0.6rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;    
        margin-left: 0.26rem;
    }
}
</style>