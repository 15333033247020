<template>
  <div>
    <span>
      @: {{ at }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    msgWrapper: { type: Object },
    index: { type: Number },
    at: { type: String }
  }
}
</script>
