import face00 from '@/assets/images/im/face/00.png'
import face01 from '@/assets/images/im/face/01.png'
import face02 from '@/assets/images/im/face/02.png'
import face03 from '@/assets/images/im/face/03.png'
import face04 from '@/assets/images/im/face/04.png'
import face05 from '@/assets/images/im/face/05.png'
import face06 from '@/assets/images/im/face/06.png'
import face07 from '@/assets/images/im/face/07.png'
import face08 from '@/assets/images/im/face/08.png'
import face09 from '@/assets/images/im/face/09.png'
import face10 from '@/assets/images/im/face/10.png'
import face11 from '@/assets/images/im/face/11.png'
import face12 from '@/assets/images/im/face/12.png'
import face13 from '@/assets/images/im/face/13.png'
import face14 from '@/assets/images/im/face/14.png'
import face15 from '@/assets/images/im/face/15.png'
import face16 from '@/assets/images/im/face/16.png'
import face17 from '@/assets/images/im/face/17.png'
import face18 from '@/assets/images/im/face/18.png'
import face19 from '@/assets/images/im/face/19.png'
import face20 from '@/assets/images/im/face/20.png'
import face21 from '@/assets/images/im/face/21.png'
import face22 from '@/assets/images/im/face/22.png'
import face23 from '@/assets/images/im/face/23.png'
import face24 from '@/assets/images/im/face/24.png'
import face25 from '@/assets/images/im/face/25.png'
import face26 from '@/assets/images/im/face/26.png'
import face27 from '@/assets/images/im/face/27.png'
import face28 from '@/assets/images/im/face/28.png'
import face29 from '@/assets/images/im/face/29.png'
import face30 from '@/assets/images/im/face/30.png'
import face31 from '@/assets/images/im/face/31.png'
import face32 from '@/assets/images/im/face/32.png'
import face33 from '@/assets/images/im/face/33.png'
import face34 from '@/assets/images/im/face/34.png'
import face35 from '@/assets/images/im/face/35.png'
import face36 from '@/assets/images/im/face/36.png'
import face37 from '@/assets/images/im/face/37.png'
import face38 from '@/assets/images/im/face/38.png'
import face39 from '@/assets/images/im/face/39.png'
import face40 from '@/assets/images/im/face/40.png'
import face41 from '@/assets/images/im/face/41.png'
import face42 from '@/assets/images/im/face/42.png'
import face43 from '@/assets/images/im/face/43.png'
import face44 from '@/assets/images/im/face/44.png'
import face45 from '@/assets/images/im/face/45.png'
import face46 from '@/assets/images/im/face/46.png'
import face47 from '@/assets/images/im/face/47.png'
import face48 from '@/assets/images/im/face/48.png'
import face49 from '@/assets/images/im/face/49.png'
import face50 from '@/assets/images/im/face/50.png'
import face51 from '@/assets/images/im/face/51.png'
import face52 from '@/assets/images/im/face/52.png'
import face53 from '@/assets/images/im/face/53.png'
import face54 from '@/assets/images/im/face/54.png'
import face55 from '@/assets/images/im/face/55.png'
import face56 from '@/assets/images/im/face/56.png'
import face57 from '@/assets/images/im/face/57.png'
import face58 from '@/assets/images/im/face/58.png'
import face59 from '@/assets/images/im/face/59.png'
import face60 from '@/assets/images/im/face/60.png'
import face61 from '@/assets/images/im/face/61.png'
import face62 from '@/assets/images/im/face/62.png'
import face63 from '@/assets/images/im/face/63.png'
import face64 from '@/assets/images/im/face/64.png'
import face65 from '@/assets/images/im/face/65.png'
import face66 from '@/assets/images/im/face/66.png'
import face67 from '@/assets/images/im/face/67.png'
import face68 from '@/assets/images/im/face/68.png'
import face69 from '@/assets/images/im/face/69.png'
import face70 from '@/assets/images/im/face/70.png'
import face71 from '@/assets/images/im/face/71.png'
import face72 from '@/assets/images/im/face/72.png'
import face73 from '@/assets/images/im/face/73.png'
import face74 from '@/assets/images/im/face/74.png'
import face75 from '@/assets/images/im/face/75.png'
import face76 from '@/assets/images/im/face/76.png'
import face77 from '@/assets/images/im/face/77.png'
import face78 from '@/assets/images/im/face/78.png'
import face79 from '@/assets/images/im/face/79.png'
import face80 from '@/assets/images/im/face/80.png'
import face81 from '@/assets/images/im/face/81.png'
import face82 from '@/assets/images/im/face/82.png'
import face83 from '@/assets/images/im/face/83.png'
import face84 from '@/assets/images/im/face/84.png'
import face85 from '@/assets/images/im/face/85.png'

import face86 from '@/assets/images/im/face/86.png'
import face87 from '@/assets/images/im/face/87.png'
import face88 from '@/assets/images/im/face/88.png'
import face89 from '@/assets/images/im/face/89.png'
import face90 from '@/assets/images/im/face/90.png'
import face91 from '@/assets/images/im/face/91.png'
import face92 from '@/assets/images/im/face/92.png'
import face93 from '@/assets/images/im/face/93.png'
import face94 from '@/assets/images/im/face/94.png'
import face95 from '@/assets/images/im/face/95.png'
import face96 from '@/assets/images/im/face/96.png'
import face_1 from '@/assets/images/im/face/-1.png'

const faceMap1 = {
    '[微笑]': require('@/assets/images/im/face/00.png'),
    '[撇嘴]': face01,
    '[色]': face02,
    '[发呆]': face03,
    '[得意]': face04,
    '[流泪]': face05,
    '[害羞]': face06,
    '[闭嘴]': face07,
    '[睡]': face08,
    '[大哭]': face09,
    '[尴尬]': face10,
    '[发怒]': face11,
    '[调皮]': face12,
    '[呲牙]': face13,
    '[惊讶]': face14,
    '[难过]': face15,
    '[囧]': face16,
    '[抓狂]': face17,
    '[吐]': face18,
    '[偷笑]': face19,
    '[愉快]': face20,
    '[白眼]': face21,
    '[傲慢]': face22,
    '[困]': face23,
    '[惊恐]': face24,
    '[流汗]': face25,
    '[憨笑]': face26,
    '[休闲]': face27,
    '[奋斗]': face28,
    '[疑问]': face29,
    '[嘘]': face30,
    '[晕]': face31,
    '[衰]': face32,
    '[骷髅]': face33,
    '[敲打]': face34,
    '[再见]': face35,
    '[擦汗]': face36,
    '[抠鼻]': face37,
    '[鼓掌]': face38,
    '[坏笑]': face39,
    '[左哼哼]': face40,
    '[右哼哼]': face41,
    '[哈欠]': face42,
    '[鄙视]': face43,
    '[委屈]': face44,
    '[快哭了]': face45,
    '[阴险]': face46,
    '[菜刀]': face47,
    '[西瓜]': face48,
    '[啤酒]': face49,
    '[咖啡]': face50,
    '[猪头]': face51,
    '[玫瑰]': face52,
    '[凋谢]': face53,
    '[嘴唇]': face54,
    '[爱心]': face55,
    '[心碎]': face56,
    '[蛋糕]': face57,
    '[炸弹]': face58,
    '[便便]': face59,
    '[月亮]': face60,
    '[太阳]': face61,
    '[拥抱]': face62,
    '[强]': face63,
    '[弱]': face64,
    '[握手]': face65,
    '[胜利]': face66,
    '[抱拳]': face67,
    '[勾引]': face68,
    '[拳头]': face69,
    '[OK]': face70,
    '[大笑]': face71,
    '[生病]': face72,
    '[破涕为笑]': face73,
    '[吐舌]': face74,
    '[恐惧]': face75,
    '[无语]': face76,
    '[嘿哈]': face77,
    '[捂脸]': face78,
    '[奸笑]': face79,
    '[机智]': face80,
    '[皱眉]': face81,
    '[鬼魂]': face82,
    '[拜托]': face83,
    '[强壮]': face84,
    '[礼物]': face85
}
const faceMap2 = {
    '[微笑]': face00,
    '[撇嘴]': face01,
    '[色]': face02,
    '[发呆]': face03,
    '[得意]': face04,
    '[流泪]': face05,
    '[害羞]': face06,
    '[闭嘴]': face07,
    '[睡]': face08,
    '[大哭]': face09,
    '[尴尬]': face10,
    '[发怒]': face11,
    '[调皮]': face12,
    '[呲牙]': face13,
    '[惊讶]': face14,
    '[难过]': face15,
    '[囧]': face16,
    '[抓狂]': face17,
    '[吐]': face18,
    '[偷笑]': face19,
    '[愉快]': face20,
    '[白眼]': face21,
    '[傲慢]': face22,
    '[困]': face23,
    '[惊恐]': face24,
    '[流汗]': face25,
    '[憨笑]': face26,
    '[休闲]': face27
}
const faceMap3 = {
    '[休闲]': face27,
    '[奋斗]': face28,
    '[疑问]': face29,
    '[嘘]': face30,
    '[晕]': face31,
    '[衰]': face32,
    '[骷髅]': face33,
    '[敲打]': face34,
    '[再见]': face35,
    '[擦汗]': face36,
    '[抠鼻]': face37,
    '[鼓掌]': face38,
    '[坏笑]': face39,
    '[左哼哼]': face40,
    '[右哼哼]': face41,
    '[哈欠]': face42,
    '[鄙视]': face43,
    '[委屈]': face44,
    '[快哭了]': face45,
    '[阴险]': face46,
    '[菜刀]': face47,
    '[西瓜]': face48,
    '[啤酒]': face49,
    '[咖啡]': face50,
    '[猪头]': face51,
    '[玫瑰]': face52,
    '[凋谢]': face53,
    '[嘴唇]': face54
}
const faceMap4 = {
    '[爱心]': face55,
    '[心碎]': face56,
    '[蛋糕]': face57,
    '[炸弹]': face58,
    '[便便]': face59,
    '[月亮]': face60,
    '[太阳]': face61,
    '[拥抱]': face62,
    '[强]': face63,
    '[弱]': face64,
    '[握手]': face65,
    '[胜利]': face66,
    '[抱拳]': face67,
    '[勾引]': face68,
    '[拳头]': face69,
    '[OK]': face70,
    '[大笑]': face71,
    '[生病]': face72,
    '[破涕为笑]': face73,
    '[吐舌]': face74,
    '[恐惧]': face75,
    '[无语]': face76,
    '[嘿哈]': face77,
    '[捂脸]': face78,
    '[奸笑]': face79,
    '[机智]': face80,
    '[皱眉]': face81,
    '[鬼魂]': face82
}
const faceMap5 = {
    '[拜托]': face83,
    '[强壮]': face84,
    '[礼物]': face85
}

const emoticonList = [
    '[微笑]',
    '[撇嘴]',
    '[色]',
    '[发呆]',
    '[得意]',
    '[流泪]',
    '[害羞]',
    '[闭嘴]',
    '[睡]',
    '[大哭]',
    '[尴尬]',
    '[发怒]',
    '[调皮]',
    '[呲牙]',
    '[惊讶]',
    '[难过]',
    '[囧]',
    '[抓狂]',
    '[吐]',
    '[偷笑]',
    '[愉快]',
    '[白眼]',
    '[傲慢]',
    '[困]',
    '[惊恐]',
    '[流汗]',
    '[憨笑]',
    '[休闲]',
    '[奋斗]',
    '[疑问]',
    '[嘘]',
    '[晕]',
    '[衰]',
    '[骷髅]',
    '[敲打]',
    '[再见]',
    '[擦汗]',
    '[抠鼻]',
    '[鼓掌]',
    '[坏笑]',
    '[左哼哼]',
    '[右哼哼]',
    '[哈欠]',
    '[鄙视]',
    '[委屈]',
    '[快哭了]',
    '[阴险]',
    '[菜刀]',
    '[西瓜]',
    '[啤酒]',
    '[咖啡]',
    '[猪头]',
    '[玫瑰]',
    '[凋谢]',
    '[嘴唇]',
    '[爱心]',
    '[心碎]',
    '[蛋糕]',
    '[炸弹]',
    '[便便]',
    '[月亮]',
    '[太阳]',
    '[拥抱]',
    '[强]',
    '[弱]',
    '[握手]',
    '[胜利]',
    '[抱拳]',
    '[勾引]',
    '[拳头]',
    '[OK]',
    '[大笑]',
    '[生病]',
    '[破涕为笑]',
    '[吐舌]',
    '[恐惧]',
    '[无语]',
    '[嘿哈]',
    '[捂脸]',
    '[奸笑]',
    '[机智]',
    '[皱眉]',
    '[鬼魂]',
    '[拜托]',
    '[强壮]',
    '[礼物]'
]

const emotionsSend = [{
        '0': ['[微笑]', face00],
        '1': ['[撇嘴]', face01],
        '2': ['[色]', face02],
        '3': ['[发呆]', face03],
        '4': ['[得意]', face04],
        '5': ['[流泪]', face05],
        '6': ['[害羞]', face06],
        '7': ['[闭嘴]', face07],
        '8': ['[睡]', face08],
        '9': ['[大哭]', face09],
        '10': ['[尴尬]', face10],
        '11': ['[发怒]', face11],
        '12': ['[调皮]', face12],
        '13': ['[呲牙]', face13],
        '14': ['[惊讶]', face14],
        '15': ['[难过]', face15],
        '16': ['[囧]', face16],
        '17': ['[抓狂]', face17],
        '18': ['[吐]', face18],
        '19': ['[偷笑]', face19],
        '20': ['[愉快]', face20],
        '21': ['[白眼]', face21],
        '22': ['[傲慢]', face22],
        '-1': ['[删除]', face_1]
    },
    {
        '23': ['[困]', face23],
        '24': ['[惊恐]', face24],
        '25': ['[流汗]', face25],
        '26': ['[憨笑]', face26],
        '27': ['[休闲]', face27],
        '28': ['[奋斗]', face28],
        '29': ['[疑问]', face29],
        '30': ['[嘘]', face30],
        '31': ['[晕]', face31],
        '32': ['[衰]', face32],
        '33': ['[骷髅]', face33],
        '34': ['[敲打]', face34],
        '35': ['[再见]', face35],
        '36': ['[擦汗]', face36],
        '37': ['[抠鼻]', face37],
        '38': ['[鼓掌]', face38],
        '39': ['[坏笑]', face39],
        '40': ['[左哼哼]', face40],
        '41': ['[右哼哼]', face41],
        '42': ['[哈欠]', face42],
        '43': ['[鄙视]', face43],
        '44': ['[委屈]', face44],
        '45': ['[快哭了]', face45],
        '-1': ['[删除]', face_1]
    },
    {
        '46': ['[阴险]', face46],
        '47': ['[菜刀]', face47],
        '48': ['[西瓜]', face48],
        '49': ['[啤酒]', face49],
        '50': ['[咖啡]', face50],
        '51': ['[猪头]', face51],
        '52': ['[玫瑰]', face52],
        '53': ['[凋谢]', face53],
        '54': ['[嘴唇]', face54],
        '55': ['[爱心]', face55],
        '56': ['[心碎]', face56],
        '57': ['[蛋糕]', face57],
        '58': ['[炸弹]', face58],
        '59': ['[便便]', face59],
        '60': ['[月亮]', face60],
        '61': ['[太阳]', face61],
        '62': ['[拥抱]', face62],
        '63': ['[强]', face63],
        '64': ['[弱]', face64],
        '65': ['[握手]', face65],
        '66': ['[胜利]', face66],
        '67': ['[抱拳]', face67],
        '68': ['[勾引]', face68],
        '-1': ['[删除]', face_1]
    },
    {
        '69': ['[拳头]', face69],
        '70': ['[OK]', face70],
        '71': ['[大笑]', face71],
        '72': ['[生病]', face72],
        '73': ['[破涕为笑]', face73],
        '74': ['[吐舌]', face74],
        '75': ['[恐惧]', face75],
        '76': ['[无语]', face76],
        '77': ['[嘿哈]', face77],

        '78': ['[捂脸]', face78],
        '79': ['[奸笑]', face79],
        '80': ['[机智]', face80],
        '81': ['[皱眉]', face81],
        '82': ['[鬼魂]', face82],
        '83': ['[拜托]', face83],
        '84': ['[强壮]', face84],
        '85': ['[礼物]', face85],

        // '86': ['[嘿哈]', face86],
        // '87': ['[捂脸]', face87],
        // '88': ['[奸笑]', face88],
        // '89': ['[机智]', face89],
        // '90': ['[皱眉]', face90],
        // '91': ['[鬼魂]', face91],
        // '92': ['[鬼魂]', face92],
        // '93': ['[拜托]', face93],
        // '94': ['[强壮]', face94],
        // '95': ['[强壮]', face95],
        // '96': ['[礼物]', face96],
        '-1': ['[删除]', face_1]
    }
]

import _yuyin_xuanzhong from '@/assets/images/im/发语音-选中@3x.png'
import _yuyin_weixuanzhong from '@/assets/images/im/发语音-未选中@3x.png'
import _xiangce from '@/assets/images/im/相册@3x.png'
import _paishe from '@/assets/images/im/拍摄@3x.png'
import _paivideo from '@/assets/images/im/视频@3x.png'
import _zanshang from '@/assets/images/im/赞赏@3x.png'
import _biaoqing_xuanzhong from '@/assets/images/im/表情-未选中@3x.png'
import _jianpan_xuanzhong from '@/assets/images/im/键盘-选中@3x.png'
import _qitagongneng from '@/assets/images/im/其他功能-选中@3x.png'
import _unqitagongneng from '@/assets/images/im/其他功能-未选中@3x.png'
import _selectPatient from '@/assets/images/im/selectpatient-icon.png'


var images = {
    xiangce: _xiangce,
    paishe: _paishe,
    paivideo: _paivideo,
    zanshang: _zanshang,
    biaoqing_xuanzhong: _biaoqing_xuanzhong,
    jianpan_xuanzhong: _jianpan_xuanzhong,
    yuyin_xuanzhong: _yuyin_xuanzhong,
    yuyin_weixuanzhong: _yuyin_weixuanzhong,
    qitagongneng: _qitagongneng,
    unqitagongneng: _unqitagongneng,
    selectPatient:_selectPatient
}

export { images, emotionsSend, faceMap1, faceMap2, faceMap3, faceMap4, faceMap5, emoticonList }