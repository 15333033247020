<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
      <!-- <cite>
        {{msgWrapper}}
        00000
      </cite> -->
    </div>
    <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text" @click="goPath">
      <div class="web__main-arrow"  />
        <div class="patient-info">
            <img src="@/assets/img/patientList-picwoman.png" v-if="msgWrapper.msg.msgBody.content.gender==2"  >
            <img src="@/assets/img/patientList-picman.png" v-else  >
            <div class="info-detail">
                <p class="name">{{msgWrapper.msg.msgBody.content.name}}</p>
                <p class="gender">{{msgWrapper.msg.msgBody.content.gender == 1?$t("im.man"):$t("im.woman")}} {{msgWrapper.msg.msgBody.content.age+$t("im.annum")}}</p>
            </div>
        </div>
        <p class="desc">{{isMyMsg?$t("im.patientDesc"):$t("im.otherPatientDesc",{name:msgWrapper.msg.fromUserName})}}</p>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        isMyMsg: {
            type: Boolean,
            default: false,
        },
        msgWrapper: { type: Object },
        index: { type: Number },
    },
    methods:{
        goPath(){
            // console.log(this.msgWrapper.msg.msgBody.content.id);
            this.$router.push({
                path:"/patient/patientDetails",
                query:{
                    patientId:this.msgWrapper.msg.msgBody.content.id
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>

.web__main-item--mine,.web__main-item {
    .web__main-text{
        background: #fff;
        padding: 0 0.6rem;
        width: 8rem;
        .web__main-arrow{
            border-left-color:#fff;
            &::after{
                border-left-color:#fff;
            }
        }
        .patient-info{
            display: flex;
            padding: 0.4rem 0;
            align-items: center;
            border-bottom: 0.02rem solid #E5E5E5;
            img{
                width: 1.6rem;
                height: 1.6rem;
                margin-right: 0.6rem;
                flex: 0 0 auto;
            }
            .info-detail{
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: flex-start;
                p{
                    margin: initial;
                }
                .name{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                .gender{
                    font-size: 0.56rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    height: 1.1rem;
                    line-height: 1.1rem;
                    
                }
            }
        }
        .desc{
            font-size: 0.44rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #838385;
            margin: initial;
        }
    }
}
</style>