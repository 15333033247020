
<template>
  <div class="web__main-item" :class="{'web__main-item--mine':isMyMsg}">
    <!--      <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>-->
    <!--      <div v-else-if="msgWrapper.sending">，</div>-->
    <div class="web__main-user">
      <img class="avator" alt="" :src="msgWrapper.msg.fromUserFaceUrl">
       
      <!-- <cite>
        {{ msgWrapper.msg.fromUserName }}
        <i>{{ msgWrapper.msg.msgId }}</i>
      </cite> -->
    </div>
<!-- shopgoods -->
     <p class="username" >{{msgWrapper.msg.chatType == 103003&&!isMyMsg?msgWrapper.msg.fromUserName:''}}</p>
    <div class="web__main-text main-over">
        <div class="web__main-arrow" />
        <div class="web_mian-share" @click="goPath(msgWrapper.msg.msgBody.content.url)">
            <p class="share-title">{{msgWrapper.msg.msgBody.content.title|| `&nbsp;&nbsp;`}}</p>
            <div class="share-box">
                <p class="share-text">{{msgWrapper.msg.msgBody.content.desc|| `&nbsp;&nbsp;`}}</p>
                <img :src="msgWrapper.msg.msgBody.content.imgUrl||require('@/assets/img/data-null.png')" >
            </div>
        </div>
    </div>
  </div>
</template>
<script>

export default {
    props: {
        isMyMsg: {
        type: Boolean,
        default: false
        },
        msgWrapper: { type: Object },
        index: { type: Number },
        chatId: { chatId: Number }
    },
    data(){
        return{
        }
    },
    created(){
    },
    methods:{
        goPath(url){
            window.location.href = url;
        }
    }
}
</script>

<style lang="scss" scoped>
    .web__main-item--mine{
        .main-over{
            background: #fff;
            max-width: 100%;
            .web__main-arrow{
                border-left-color: #fff;
                &::after{
                    border-left-color: #fff;
                }
            }
        }
    }
    .main-over{
        padding: 0;
        width: 100%;
        min-width: 6rem;
        .web_mian-share{
            // background: #fff;
            padding:0 .6rem;
            .share-title{
                font-size: .56rem;
                line-height: 1.4rem;
                font-weight: 600;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .share-box{
                display: flex;
                align-items: center;
                margin-bottom: .5rem;
                .share-text{
                    font-size: .5rem;
                    font-weight: 500;
                    color: #838385;
                    flex: 1;
                    line-height: .68rem;
                    margin-right: .3rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                img{
                    width: 1.56rem;
                    flex:  0 0 auto;
                    border-radius: .1rem;
                    height: 1.56rem;
                }
            }
        }
    }
</style>
