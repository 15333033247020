<template>
  <div class="unknown">
    <!-- <div v-if="msgWrapper.sendError" @click="onResend(msgWrapper, index)">，</div>
    <div v-else-if="msgWrapper.sending">，</div>
    <img :src="msgWrapper.msg.fromUserFaceUrl">//isMyMsg【】【】 -->
    <span>{{$t('im.nonsupportMsg')}}</span>
    <!-- <span>，id：{{ msgWrapper.msg.id }}</span>
    <span>，：{{ msgWrapper.msg.msgBody.type }}</span> -->
  </div>
</template>
<script>
export default {
  props: {
    isMyMsg: {
      type: Boolean,
      default: false
    },
    msgWrapper: { type: Object },
    index: { type: Number },
    onResend: { type: Function }
  }
}
</script>
<style lang="scss" scoped>
  .unknown{
    margin: 0.5rem;
    text-align: center;
    span{
      padding: 0.1rem 0.2rem;
      border: 0.05rem solid #A8A8AB;
      /* margin: 0.75rem 0; */
      color: #A8A8AB;
      border-radius: 0.1rem;
    }
  }
</style>
