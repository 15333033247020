<template>
    <p class="txtd" v-html="texts"></p>
</template>
<script>
export default {
  props: {
    msgWrapper: { type: Object },
    index: { type: Number },
    text: { type: String }
  },
  data(){
    return{
      texts:""
    }
  },
  mounted(){
    this.isHaveUrl();
  },
  methods:{
    isHaveUrl(){
     
      var txtContent=this.text;
      var httpReg = new RegExp(/(([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})+[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])|((https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])/g,"gi"); //用于判断文本内容超链接
      //文本地址变为a链接
      var  formatTxtContent = txtContent.replace(httpReg,function(httpText) {
          return '<a href="' + (httpText.indexOf('http')>-1?httpText:'http://' + httpText) + '" class="msghref" target="_blank">' + httpText + '</a>';
      });
      this.texts = formatTxtContent;
    }
  }
}
</script>
<style lang="less" >
  .web__main-item--mine{
    .msghref{
      color: #fff !important;
    }  
  }
.txtd{
  // width: max-content;
  max-width: 100%;
  // // text-align: center;
  margin: 0 auto;

  .msghref{
    color: #333333 !important;
    text-decoration: underline;
  }
}
</style>
