<template>
  <div class="im-chat-continer">
    <div class="gocc" v-if="createdShow">
      <van-popup v-model="createdShow" :close-on-click-overlay="false" round><img src="@/assets/images/Customer-service-waiting.gif" alt=""></van-popup>
    </div>
    <div class="wrapper" :class="{'isartificial':toUserIsRobot&&toUserIsRobot != 0}" v-else>
      <div class="scroller" @click="isShowBottom" :class="isShowMoreFunctions?'chat-main':isShowFaceList?'expressionchat-main':''" ref="scroller">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div id="topEle"/>
          <msg-list ref="msgListEle" :msg-list="msgList" :chatId="chatId"/>
          <div id="endEle"/>
        </van-pull-refresh>
      </div>
      <!--  -->
      <div class="send-content" @click="getScollrchBottom">
        <!-- im-artificial.png -->
        <div class="send-bar">
          <!-- 转人工消息 -->
          <!-- tenantImConfig -->
          <div class="toArtificial" @click="toArtificial" v-if="tenantImConfig.showToHumanBtn==1&&toUserIsRobot == 1" >
            <img src="@/assets/img/im-artificial.png" alt=""><span>{{$t('im.toArtificial')}}</span>
          </div>
          <img class="voice-icon" v-if="recordingShow&&!isShowVoice" :src="images.yuyin_weixuanzhong" alt="" @click="showVoice">
          <img class="voice-icon input-icon" v-if="recordingShow&&isShowVoice" src="@/assets/img/input-icon.png" @click="showVoice">

          <!--  -->
          <div class="box-voice " @touchmove="moveRecord"  @touchend="stopRecord" @touchstart="startRecord" v-show="isShowVoice"> <i class="after"></i> <button>{{$t("im.loosenSend")}}</button></div>
          <div class="boxprent"  v-show="!isShowVoice">
            <!-- {{sendType}} -->
            <im-input
              id="message"
              ref="imInput"
              :child="inputHtmlContent"
              :edit-focus="editFocus"
              :edit="edit"
              :send-type="sendType"
              :is-show-face-list="isShowFaceList"
              :is-show-more-functions="isShowMoreFunctions"
              @click.native="editClick"
            />
          </div>
          <img
            class="send-face"
            :src="isShowFaceList? images.jianpan_xuanzhong:images.biaoqing_xuanzhong"
            @click="showFaceList"
          >
          <el-button v-if="inputTextContent!==''" ref="sendBtn" size="mini" @click="sendTextMsg">{{$t("im.sendbtn")}}</el-button>
          <template v-else>
            <img class="send-more" v-if="tenantImConfig.cusAppCamera == 1||tenantImConfig.cusAppPatient == 1||tenantImConfig.cusAppPicture == 1||tenantImConfig.cusAppVideo == 1" :src="isShowMoreFunctions?images.qitagongneng:images.unqitagongneng" @click="showMoreFunctions">
          </template>
        </div>

        <div v-if="isShowFaceList||isShowMoreFunctions">
          <div class="van-swipe-div" v-if="isShowFaceList">
            <van-swipe :loop="false">
              <van-swipe-item v-for="(faceItems, index1) in emotionsSend" :key="index1" class="swiper-item">
                <img
                  v-for="(item, index2) in faceItems"
                  :key="index2"
                  v-lazy="item[1]"
                  @click="onFaceClick(item[0], item[1],$event)"
                  :class="['face-item',{'face-special':index2 == -1}]"
                >
              </van-swipe-item>
            </van-swipe>
          </div>
          <!--  -->
          <div v-else-if="isShowMoreFunctions" class="gongneng">

            <van-uploader
              :after-read="afterRead"
              preview-full-image
              capture="camera"
              ref="cameras"
              v-if="tenantImConfig.cusAppCamera == 1"
              :multiple="false"
              :max-count="1"
            >
              <!-- <van-grid-item
                :icon="images.paishe"
                text=""
              /> -->
              <div class="gongneng-grid-item">
                <img :src="images.paishe" class="icon">
                <p class="text">{{$t("im.photograph")}}</p>
              </div>
              <div class="item-overlay" v-if="imgTypeList.length != 0" @click="uploadSelectLabel('cameras')"></div>
            </van-uploader>

            <van-uploader
              :after-read="afterRead"
              accept="image/*"
              preview-full-image
              v-if="tenantImConfig.cusAppPicture == 1"
              ref="photoImg"
              :multiple="true"
              :max-count="6"
            >
              <div class="gongneng-grid-item">
                <img :src="images.xiangce" class="icon">
                <p class="text">{{$t("im.photoAlbum")}}</p>
              </div>
              <div class="item-overlay" v-if="imgTypeList.length != 0" @click="uploadSelectLabel('photoImg')"></div>
            </van-uploader>

            <van-uploader
              :after-read="afterVideo"
              accept="video/*"
              v-if="tenantImConfig.cusAppVideo == 1"
              preview-full-image
            >
              <div class="gongneng-grid-item">
                <img :src="images.paivideo" class="icon">
                <p class="text">{{$t("im.video")}}</p>
              </div>
            </van-uploader>

            <!--          <div class="van-uploader" @click="sendPatient" v-if="tenantImConfig.cusAppPatient == 1">
                        <div class="gongneng-grid-item">
                          <img :src="images.selectPatient" class="icon">
                          <p class="text">{{$t("im.patient")}}</p>
                        </div>
                      </div>-->
            <!-- <input type="file"    name="" value="" > -->


            <!-- <van-grid :border="false">
              <van-grid-item>
                <van-uploader
                  :after-read="afterRead"
                  preview-full-image
                  capture="camera"
                  :multiple="false"
                  :max-count="1"
                >
                  <van-grid-item
                    :icon="images.paishe"
                    text=""
                  />
                </van-uploader>

              </van-grid-item>
              <van-grid-item>
                <van-uploader
                  :after-read="afterRead"
                  accept="image/*;video/*"
                  preview-full-image
                  :multiple="true"
                  :max-count="6"
                >
                  <van-grid-item
                    :icon="images.xiangce"
                    text=""
                  />
                </van-uploader>

              </van-grid-item>
              <van-grid-item
                :icon="images.zanshang"
                text=""
                style="margin-top:0"
              />

            </van-grid> -->
          </div>
        </div>
      </div>
      <!--    -->
      <div v-show="isRecording" class="bo-box">
        <img v-if="touchTop < 100" src="@/assets/img/Recor-cion-select.png">
        <img src="@/assets/img/recor-clare-icon.png" v-else >
        <p class="recoreTime">{{secoreTime}}</p>
        <p class="text" :class="{'clareTxt':touchTop > 100}">{{touchTop > 100?$t('im.sendVoiceCancel'):$t("im.sendVoice")}}</p>
      </div>
      <van-overlay :show="selectLabelShow" class-name="img-labeloverlay">
        <div class="lable-container">
          <p class="title">{{$t("im.uploadType")}}</p>
          <div class="container-sect">
            <div>
              <p v-for="(item, index) in imgTypeList" :class="{'avtive':labelActive.indexOf(item.value)>-1}" @click="selectLabel(item)" :key="index">
                {{item.name}}
                <van-icon name="success" v-if="labelActive.indexOf(item.value)>-1"></van-icon>
              </p>
            </div>
          </div>
          <div class="container-bottom">
            <div class="label-btn" :class="{'btn-disabled':labelActive.length==0}" @click="labelsubmit">
              {{$t("im.chosenOk")}}
            </div>
          </div>
        </div>
        <i class="close-box" @click="selectLabelShow = false"></i>
      </van-overlay>
    </div>
  </div>

</template>
<script>

import { buildImgMsgBody, buildTextMsgBody, newSendingMsg, toMsgWrapper, buildRecordMsgBody, buildVideoMsgBody, buildPatientBody } from './imapi'
import {
  images as _images,
  emotionsSend as _emotionsSend
} from './faceData'
import { config_get } from "@/api/user"
import MsgList from './components/MsgList.vue'
import ImInput from './components/ImInput.vue'
import { setLocaltion } from "@/utils/localtion";

export default {
  components: {
    MsgList,
    ImInput
  },
  data() {
    return {
      //
      labelActive:[],
      selectLabelShow:false,
      toUserIsRobot:null,
      myId: this.$store.getters.id,
      myName: this.$store.getters.name,
      myFaceUrl: this.$store.getters.avatar,
      edit: true,
      uploadRef:"",
      recordingShow:false,
      editFocus: true,
      inputHtmlContent: { html: '' },
      inputTextContent: '',
      emotionsSend: _emotionsSend,
      images: _images,
      isLoading: false,
      isApiLoading: false,
      isShowVoice: false,
      isShowFaceList: false,
      isShowMoreFunctions: false,
      msgList: [],
      chatId: null,
      timer: null,
      isRecording:false,
      sendType:false,
      createdShow:true,
      clearTime:"",
      secoreTime:this.format(0),
      touchTop:0,
      recordTimer:null,
      fristTouchY:null,
      lastTouchY:null,
      // 图片连续查看 下标锚点
      imgIndex:0,
      imgTypeList:[
       /*  {name:"检查单",value:"检查单"},
        {name:"检验单",value:"检验单"},
        {name:"病历",value:"病历"},
        {name:"其他",value:"其他"}, */
      ],
      tenantImConfig:{
        cusAppCamera: 0,
        cusAppPatient: 0,
        cusAppPicture: 0,
        cusAppVideo: 0
      }
    }
  },
  computed:{
		getters(){
			return this.$store.getters
		}
	},
  watch: {
    'getters':{
			handler(news,old){
				if(this.$store.getters.patientSelectIndex != null){
          this.sendPatientMsg(this.$store.getters.patientData[this.$store.getters.patientSelectIndex]);
					this.$store.commit("user/SET_PATIENTINDEX",null);
				}
			},
			deep:true
		},
    inputHtmlContent: {
      handler(newValue, oldvalue) {
        // console.log("ldldld");
        this.inputTextContent = newValue.html
        var regex = /(.*?)<img.*?alt="(.*?)">/gi
        var result = this.inputTextContent.replace(regex, '$1$2')
        this.inputTextContent = result
          .replace(/&nbsp;/g, ' ')
          .replace(/&amp;/g, '&')
          .replace(/<\/?[^>]*>/g, '\n')
      },
      deep: true
    }
  },

  async created() {
    this.getImConfig()
    document.title = this.$t('im.mychat');
    if(!this.$route.query.chatId){
     this.chatId = await this.createDialogue();
    }else{
      this.chatId = Number(this.$route.query.chatId);
      this.chatGet()
    }
    this.msgQuery('init');
    document.title = this.$t("im.name");
    if(this.is_weixin()){
      this.recordingShow = true;
    }
    this.$imapiAxio.post('/im/chat-read',{chatId:this.chatId}).then(result => {})
    setLocaltion();
    let that = this;
    window.removeEventListener("beforeunload", e => {
      that.$imapiAxio.post('/im/chat-read').then(result => {})
    });
  },

  // mounted(){},
  destroyed() {
    this.$imapiAxio.post('/im/chat-read',{chatId:this.chatId}).then(result => {})
    clearInterval(this.timer);
  },
  methods: {
    getImConfig(){
      config_get({}).then(res =>{
        this.tenantImConfig = res.data.data.tenantImConfig
      })
    },
    sendPatient(){
      this.$store.commit("user/SET_SENDPATIENT",true);
    },
    async createDialogue() {
      const res = await this.$imapiAxio.post(
        '/im/cc-chat-create',
        {
          sessionEnv:{
            fromPage:this.$route.query.fromPage,
            objId:this.$route.query.objId,
            objValue:this.$route.query.objValue,
          }
        }
      )
      if(res.errorCode == 0){
        if(res.data.toUserName){
          document.title = res.data.toUserName
        }
        this.toUserIsRobot = res.data.toUserIsRobot;
        return res.data.chatId;
      }else{
        return 1;
      }
      // console.log(res);
      //  this.chatId = res.data.chatId;
      // return res.data.chatId;
    },
    async sendPatientMsg(data){
       	var msgBody = buildPatientBody(data)
        // console.log(this.inputTextContent,msgBody);
        var msgItem = toMsgWrapper(
          newSendingMsg(this.chatId,
          msgBody, "【就诊人】", this.myId, this.myName, this.myFaceUrl
          )
        )
        msgItem.sending = true;

        this.msgList.push(msgItem);

        setTimeout(()=>{
          this.getScollrchBottom();
        },500)
        // console.log(msgBody);
        // return;
        const resData = await this.$imapiAxio.post(
          '/im/msg-send',
          {
            chatId: this.chatId,
            msgBody: msgBody,
            msgSummary: '【就诊人】',
            sessionEnv: {}
          }
        )
        // this.$imapiAxio.post(
        //   '/im/set-patient',
        //   {
        //     chatId: this.chatId,
        //     patientId: data.id,
        //   }
        // )

        Object.assign(msgItem.msg, resData.data)
        msgItem.sending = false
    },
    labelsubmit(){
      if(this.labelActive.length == 0){
        this.$toast(this.$t("im.uploadType"))
        return;
      }
      console.log(this.$refs[this.uploadRef].$el);
      this.$refs[this.uploadRef].$el.getElementsByClassName('van-uploader__input')[0].click();
    },
    //
    async toArtificial(){
      const res = await this.$imapiAxio.post(
          '/im/cc-chat-to-human',
          {
            chatId:this.chatId
          }
        )
        if(res.errorCode == 0){
          this.toUserIsRobot = 0;
          this.inputTextContent = this.$t("im.toservice");
          this.sendTextMsg()
        } else {
          // this.toUserIsRobot = 0;
        }
    },
    async chatGet(){
      const res = await this.$imapiAxio.post(
        '/im/chat-get',
        {
          chatId:this.chatId
        }
      )
      if(res.errorCode == 0){
        // this.toUserIsRobot
        if(res.data.toUserName){
          document.title = res.data.toUserName
        }
        this.toUserIsRobot = res.data.toUserIsRobot;
      }

    },
    // 选择上传图片标签
    selectLabel(item){
      this.labelActive = [item.value]
    },
    uploadSelectLabel(ref){
      this.uploadRef = ref;
      this.selectLabelShow = true;
      this.labelActive = [];
    },
	//
	startRecord(){
		try {
			wx.startRecord();
      clearInterval(this.recordTimer);
			this.isRecording = true;
			let num = 1;
			this.recordTimer =  setInterval(()=>{
				this.secoreTime = this.format(num++);
			},1000)
		} catch (error) {
			this.$toast(this.$t("im.msgSendaudiofail"))
		}
	},
	moveRecord(e){
		if(!this.fristTouchY){
			this.fristTouchY = e.targetTouches[0].pageY;
			let selt = this;
      let top = selt.touchTop;

			wx.onVoiceRecordEnd({
			//  complete
				complete:async function (calRes) {
					if(top<100){
						wx.uploadVoice({
							localId: calRes.localId, // ID，stopRecord
							isShowProgressTips: 1, // 1，
							success: async function (res) {
								var serverId = res.serverId;
								let result = await selt.$uploadAxios.post(
									'/upload/im/sound/wx',
									{mediaId:serverId}
								)
								if(result.errorCode == 0){
									selt.SendRecord({
										len:selt.time_to_sec(selt.secoreTime),
										url:result.data.url,
										serverId:serverId
									});
								}else{
									selt.$toast(this.$t("im.msgSendaudiofail"));
								}
								this.secoreTime = this.format(0);
							}
						});
					}
				}
			});
		}
		this.lastTouchY = e.targetTouches[0].pageY;
		this.touchTop = this.fristTouchY  - this.lastTouchY;
		// console.log(this.touchTop);
	},
	async stopRecord(){
		this.isRecording = false;
    let times = this.secoreTime;
    this.secoreTime = this.format(0);
		clearInterval(this.recordTimer);
		let selt = this;
		// this.$toast("")
		if(selt.touchTop<100){
      let top = selt.touchTop;
      // alert(";---"+this.secoreTime);
      if(this.secoreTime<1){

        this.$toast(this.$t("im.voiceShort"));
        return;
      }
			try {
				await wx.stopRecord({
					success: async function (calRes) {
					  await	wx.uploadVoice({
							localId: calRes.localId, // ID，stopRecord
							isShowProgressTips: 1, // 1，
							success: async function (res) {
								var serverId = res.serverId;
								let result = await selt.$uploadAxios.post(
									'/upload/im/sound/wx',
									{mediaId:serverId}
								)
								if(result.errorCode == 0){
									selt.SendRecord({
										len:selt.time_to_sec(times),
										url:result.data.url,
									});
								}else{
									selt.$toast(this.$t("im.msgSendaudiofail"));
								}
							}
						});
					}
				});

			} catch (error) {
				this.$toast(error)
			}
		}
		this.touchTop = 0;
	  // wx.startRecord();
	},
	async SendRecord(RecordData){

		// this.$toast(JSON.stringify(RecordData))
		var msgBody = buildRecordMsgBody(RecordData)
		// console.log(this.inputTextContent,msgBody);
		var msgItem = toMsgWrapper(
			newSendingMsg(this.chatId,
			msgBody, "【】", this.myId, this.myName, this.myFaceUrl
			)
		)
		msgItem.sending = true;

		this.msgList.push(msgItem);
		setTimeout(()=>{
			this.getScollrchBottom();
		},500)
		// return;
		const resData = await this.$imapiAxio.post(
			'/im/msg-send',
			{
				chatId: this.chatId,
				msgBody: msgBody,
				msgSummary: '【】',
				sessionEnv: {}
			}
		)
		Object.assign(msgItem.msg, resData.data)
		msgItem.sending = false
	},
	format(seconds) {
		let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
		seconds -= 3600 * hour;
		let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
		seconds -= 60 * min;
		let sec = seconds >= 10 ? seconds : '0' + seconds;
		return  min + ':' + sec;
	},
	time_to_sec(s) {
		let ss = s.split(":");
		let t = Math.floor(ss[0]) * 60 + Math.floor(ss[1]);
		return t;
	},
    is_weixin(){
        var ua = navigator.userAgent.toLowerCase();
		if(ua.match(/MicroMessenger/i)=="micromessenger") {
			return true;
		} else {
			return false;
		}
    },
    editClick() {
      // var shell = new ActiveXObject("wscript.shell");
      // shell.run("osk.exe");

      this.isShowFaceList = false
      this.isShowMoreFunctions = false
      this.editFocus = true;
      setTimeout(()=>{
        this.getScollrchBottom();
      })
    },
    //
    getScollrchBottom(){
      console.log(this.$refs);
      this.$refs.scroller.scrollTop = this.$refs.msgListEle.$el.scrollHeight;
    },
    //
    isShowBottom() {
      this.isShowFaceList = false
      this.isShowMoreFunctions = false
    },
    //
    showVoice() {
     /*  this.isShowVoice = !this.isShowVoice;
      this.isShowMoreFunctions = false;
      this.isShowFaceList = false;
      this.sendType = false; */
    },
    //
    showFaceList() {
		this.isShowVoice = false;
      	this.isShowFaceList = !this.isShowFaceList
      if (this.isShowFaceList) {
        this.isShowMoreFunctions = false
      }
    },
    //
    showMoreFunctions() {
		this.isShowVoice = false;
		this.isShowMoreFunctions = !this.isShowMoreFunctions
      if (this.isShowMoreFunctions) {
        this.isShowFaceList = false
		}
    },
    // +
    async sendTextMsg(e,type) {
      this.isShowMoreFunctions = false;
      this.isShowFaceList = false;
      this.sendType = true;
      if(!type){
        if(e){
          e.preventDefault()
        }
      }

      var msgBody = buildTextMsgBody(type?e:this.inputTextContent)
      // console.log(this.inputTextContent,msgBody);
      var msgSummary = type?e:this.inputTextContent;
      var msgItem = toMsgWrapper(
        newSendingMsg(this.chatId,
          msgBody, msgSummary, this.myId, this.myName, this.myFaceUrl
        )
      )

      this.inputHtmlContent.html = '';

      msgItem.sending = true
      this.msgList.push(msgItem)
      setTimeout(()=>{
        this.getScollrchBottom();
      },50)
      // return;
      const resData = await this.$imapiAxio.post(
        '/im/msg-send',
        {
          chatId: this.chatId,
          msgBody: msgBody,
          msgSummary: msgSummary,
          sessionEnv: {}
        }
      )
      Object.assign(msgItem.msg, resData.data)
      msgItem.sending = false
    },
    //
    onFaceClick(data, img, e) {
      e.preventDefault();
      if (data === '[删除]') {
        const reg = /<img[^<>]+>/g
        const imgs = this.inputHtmlContent.html.match(reg)
        const lastImg = imgs[imgs.length - 1]
        const lastStr = this.inputHtmlContent.html.slice(-lastImg.length)
        if (lastStr === lastImg) {
          this.inputHtmlContent.html = this.inputHtmlContent.html.substring(
            0,
            this.inputHtmlContent.html.length - lastImg.length
          )
        }
      } else {
        const _img = `<img src="${img}" style="width:0.6rem;height:0.6rem" alt="${data}">`
        this.inputHtmlContent.html = this.inputHtmlContent.html + _img
      }
    },

    //
    async msgQuery(stage) {
      if (this.isApiLoading) {
        return
      }

      this.isApiLoading = true

      let preUserMsgId = 0
      if (this.msgList && this.msgList.length > 0) {
        preUserMsgId = this.msgList[0].msg.msgId
      }
      const resData = await this.$imapiAxio.post(
        '/im/msg-query',
        {
          chatId: this.chatId,
          preUserMsgId: preUserMsgId,
          num: 10
        }
      )
      this.createdShow = false;
      if (resData.data != null && resData.data.length > 0) {
        for (let i = 0; i < resData.data.length; i++) {
          this.msgList.unshift({...toMsgWrapper(resData.data[i]),showPopover:false,withdrawShow:false,})
        }
      }
      this.isApiLoading = false
      if (this.isLoading) {
        this.isLoading = false
      }
      if (stage === 'init') {
        setTimeout(()=>{
          this.getScollrchBottom();
        },10)
        this.heartBeep()
      }
    },
    //
    async heartBeep() {
      var ele = this.$el.querySelector(`#endEle`)

      var resData = await this.$imapiAxio.post('/im/heart-beep', {})
      if (resData.data != null && resData.data.length > 0) {
        // resData.data.map((item) => {
        //   if (item.fromUserName === null) {
        //     item.fromUserName = ''
        //   }
        // })
        if(resData.data.length>0){
          this.$imapiAxio.post('/im/chat-read',{chatId:this.chatId}).then(result => {})
        }
        for (let i = 0; i < resData.data.length; i++) {

          if (resData.data[i].fromUserName === null) {
            resData.data[i].fromUserName = ''
          }
          if(resData.data[i].chatId == this.chatId){
            this.msgList.push(toMsgWrapper(resData.data[i]))
          }
        }
        let indexs;
        // console.log("tgus",this.msgList);
        for (let i = 0; i < resData.data.length; i++) {
          if(resData.data[i].msgBody.type == 'revoke'){
              this.msgList.forEach((item,index)=>{
                // console.log(resData.data[i].msgBody.content.msgId == item.msg.chatMsgId,resData.data[i].msgBody.content.msgId , item.msg.chatMsgId);
                if(resData.data[i].msgBody.content.msgId == item.msg.chatMsgId){
                  indexs = index;
                }
              })
            }
        }
        if(indexs){
          this.msgList.splice(indexs,1);
        }
        setTimeout(()=>{
          this.getScollrchBottom();
        },1000)
        ele.scrollIntoView({
          behavior: 'smooth', //
          block: 'end' // 。
        })
      }

      // var eleId = 'msg' + this.msgList[this.msgList.length - 1].msg.msgId
      // var ele = this.$refs.msgListEle.$refs['msg' + this.msgList[this.msgList.length - 1].msg.msgId]
        // console.log(";;;;");
      if(!this.timer){
        this.timer = setInterval(()=>{
          this.heartBeep();
        }, 1000);
      }
    },
    //
    async afterRead(file) {
      //
      this.selectLabelShow = false;
      this.isShowMoreFunctions = false;
      this.isShowFaceList = false;
      //
      var files = []
      if (file instanceof Array) {
        files = file
      } else {
        files.push(file)
      }

      files.forEach(async (item, index) => {
        await this.sendOneImg(item)
      })
    },
    async afterVideo(file){
      var msgBody = buildVideoMsgBody(file.content,file)
      var msgSummary = '['+this.$t("im.video")+']'
      var msgItem = toMsgWrapper(
        newSendingMsg(this.chatId,
          msgBody, msgSummary, this.myId, this.myName, this.myFaceUrl
        )
      )
      msgItem.sending = true
      this.msgList.push(msgItem);
      // setTimeout(()=>{
      //   const video = document.getElementById('video'+msgItem.msg.msgCdt);
      //   console.log(video,'video'+msgItem.msg.msgCdt);
      //   video.src = file.content;
      //   video.click();
      //   video.onloadeddata = function () {
      //     var canvas = document.createElement("canvas"); //
      //     canvas.width = video.videoWidth;
      //     canvas.height = video.videoHeight;
      //     var flag = true;
      //       for (var flag = true; flag;) {
      //           if (canvas.width > 400) {
      //               canvas.width *= 0.9;
      //               canvas.height *= 0.9;
      //           } else {
      //               flag = false;
      //           }
      //       }
      //       console.log(canvas.width,canvas.height);
      //     canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height); // getContext:；drawImage:
      //     // console.log(canvas.toDataURL("image/png"));
      //     var imagebase64 = canvas.toDataURL('image/png'); //imgbase64
      //     msgItem.msg.msgBody.content.smallImg.url = imagebase64;
      //     console.log(imagebase64);
      //   }
      // },10)



      console.log(this.msgList);
      setTimeout(()=>{
        this.getScollrchBottom();
      },100)
      const uploaddata = {
        data: file.content,
        fileName: file.file.name
      }
      const uploadResData = await this.$uploadAxios.post(
        '/upload/im/video/base64-upload',
        uploaddata
      )
      msgItem.msg.msgBody.content.bigImg.url = uploadResData.data.bigImg.url
      msgItem.msg.msgBody.content.bigImg.width = uploadResData.data.bigImg.width
      msgItem.msg.msgBody.content.bigImg.height = uploadResData.data.bigImg.height
      msgItem.msg.msgBody.content.smallImg.url = uploadResData.data.smallImg.url
      msgItem.msg.msgBody.content.smallImg.width = uploadResData.data.smallImg.width
      msgItem.msg.msgBody.content.smallImg.height = uploadResData.data.smallImg.height
      msgItem.msg.msgBody.content.len = uploadResData.data.len
      msgItem.msg.msgBody.content.url = uploadResData.data.url


      const resData = await this.$imapiAxio.post(
        '/im/msg-send',
        {
          chatId: this.chatId,
          msgBody: msgBody,
          msgSummary: msgSummary,
          sessionEnv: {}
        }
      )
      Object.assign(msgItem.msg, resData.data)
      msgItem.sending = false;
    },
    async sendOneImg(file) {
      var msgBody = buildImgMsgBody(file.content)
      var msgSummary = '['+this.$t("im.photo")+']'
      var msgItem = toMsgWrapper(
        newSendingMsg(this.chatId,
          msgBody, msgSummary, this.myId, this.myName, this.myFaceUrl,
        )
      )
      msgItem.sending = true
      this.imgIndex++
      msgItem.imgIndex = this.imgIndex;
      this.msgList.push(msgItem)

      setTimeout(()=>{
        this.getScollrchBottom();
      },100)
      const uploaddata = {
        data: file.content,
        fileName: file.file.name
      }

      const uploadResData = await this.$uploadAxios.post(
        '/upload/im/image/base64-upload',
        uploaddata
      )

      msgItem.msg.msgBody.content.big.url = uploadResData.data.big.url
      if(this.labelActive.length!=0){
        msgItem.msg.msgBody.content.tags = this.labelActive;
      }
      msgItem.msg.msgBody.content.big.width = uploadResData.data.big.width
      msgItem.msg.msgBody.content.big.height = uploadResData.data.big.height
      msgItem.msg.msgBody.content.small.url = uploadResData.data.small.url
      msgItem.msg.msgBody.content.small.width = uploadResData.data.small.width
      msgItem.msg.msgBody.content.small.height = uploadResData.data.small.height

      const resData = await this.$imapiAxio.post(
        '/im/msg-send',
        {
          chatId: this.chatId,
          msgBody: msgBody,
          msgSummary: msgSummary,
          sessionEnv: {}
        }
      )
      Object.assign(msgItem.msg, resData.data)
      msgItem.sending = false
    },
    //
    async onRefresh() {
      let scorlltop = this.$refs.msgListEle.$el.scrollHeight;
      await this.msgQuery();
      this.$refs.scroller.scrollTop = this.$refs.msgListEle.$el.scrollHeight - scorlltop;
    },


  }
}
</script>
<style lang="scss" scoped>
.im-chat-continer{
  .chat-main{
    .web__main{
      padding-bottom: 2rem;
    }
  }
  .expressionchat-main{
    .web__main{
      padding-bottom: 2rem;
    }
  }
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .scroller {
      height: 100%;
      flex: 1;
      background: #F5F6FA;
      .van-pull-refresh,.van-pull-refresh__track,.web__main{
        min-height: 100%;
      }
    }
  }
  .isartificial{
    .web__main{
      padding-bottom: 2rem;
    }
  }

  .send-content {
    width: 100%;
    flex: 0 0 auto;
    // position: fixed;
    // bottom: 0;
    max-width: 750px;

    .van-swipe-div {
      background: #fff;
    }
    .van-swipe{
      padding-bottom: 0.5rem;
      .van-swipe__track{
        .van-swipe-item{
          box-sizing: border-box !important;
          display: flex;
          flex-wrap: wrap;
          justify-content: left;
          width: 13.75rem !important;
          margin: 0 0.64rem;
        }

      }
    }
    .send-bar {
      display: flex;
      flex-direction: row;
      background: #fff;
      padding: 0.8rem 0.6rem;
      height: 1.36rem;
      align-items: center;
      position: relative;
      .toArtificial{
        position: absolute;
        top: -1.5rem;
        left: 0.6rem;
        background-color: #fff;
        border: 1px solid #49A7FF;
        display: flex;
        align-items: center;
        padding: 0.2rem 0.48rem;
        border-radius: 0.8rem;
        font-size: 0.6rem;
        img{
          width: 0.48rem;
          height: 0.58rem;
          margin-right: 0.3rem;
        }
        span{
          font-size: 0.6rem;
        }
      }
      .voice-icon {
        width: 0.64rem;
        height: 0.96rem;
        margin-right: 0.48rem;
        padding-right: .32rem;
        flex: auto 0 0;
      }
      .input-icon{
        width: .96rem;
        padding-right: 0;
        height: .96rem;
      }
      .box-voice {
        height: 1.36rem;
        background: #ECEEF3;
        border-radius: .68rem;
        flex: 1;
        font-size: .56rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        user-select: none;
        & .after{
          display: block;
          z-index: 9;
          // background: red;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        button{
          border: 0;
          background: none;
          user-select: none;
        }
      }
      .boxprent{
        flex: 1;
        background: #ECEEF3;
        border-radius: 3rem;
        outline-style: none;
        overflow: hidden;
      }
      .send-face {
        margin:0 0.48rem;
      }
      .send-face,.send-more{
        width:0.98rem;
        height:0.98rem;
      }
      .el-button {
        font-size: 0.64rem;
      }
    }

    .face-item{
      width:1.4rem;
      height:1.4rem;
      margin-left: 0.36rem;
      margin-top: 0.36rem;
      &:nth-child(8n+1){
        margin-left: 0;
      }
    }
    .face-special{
      height:1.25rem;
    }
    .gongneng{
      background: #fff;
      padding: 0 1.3rem;
      display: flex;
      flex-wrap: wrap;
      margin-top: -.06rem;
      // justify-content: space-between;
      .van-uploader{
        margin-bottom: 0.5rem;
        margin-left: 1.16rem;
        position: relative;
        .item-overlay{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 111;
        }
        &:nth-child(4n+1){
          margin-left: 0;
        }
        .icon{
          width: 2.22rem;
          height: 2.22rem;
          display: block;
        }
        .text{
          font-size: 0.44rem;
          margin-top: 0.34rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          // max-width: 58px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
        }
      }
    }

  }
  .gocc{
    img{
      width: 8rem;
      height: 8rem;
    }
  }
  .bo-box {
    position: fixed;
    width: 6.4rem;
    height: 5.74rem;
    background: #323232;
    border-radius: .48rem;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 0;
    transform: translate(-50%,-50%);
    img{
      width: .9rem;
      height: 1.36rem;
    }
    .recoreTime{
      margin-top: .4rem;
      font-size: .44rem;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
    .text{
      font-size: .48rem;
      font-weight: 400;
      color: #fff;
      margin-top: .8rem;
    }
    .clareTxt{
      color: #F61818;
    }
  }
  .img-labeloverlay{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .lable-container{
      width: 11.2rem;
      height: 14.2rem;
      background: url("~@/assets/img/img-label-back.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 2rem 0.8rem 0 0.8rem;
      display: flex;
      flex-direction: column;
      position: relative;
      &::after{
        content: ' ';
        position: absolute;
        width: 0.2rem;
        height: 2rem;
        top: -1rem;
        left: 50%;
        transform: translate(-110%, 10%);
        background: url("~@/assets/img/img-label-icon.png") no-repeat;
        background-size: 100% 100%;
      }
      .title{
        font-size: 0.72rem;
        text-align: center;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .container-sect{
        flex: 1;
        overflow: auto;
        padding: 0.6rem 0;
        align-items: center;
        justify-content: center;
        display: flex;
        p{
          float: left;
          font-size: 0.64rem;
          padding: 0.2rem 0.8rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #909399;
          background: #F5F6FA;
          margin-bottom: 0.3rem;
          margin-right: 0.2rem;
          border-radius: .16rem;
        }
        .avtive{
          background: #fff;
          color: #3F9EFF;
          border: 0.04rem solid #3F9EFF;
          padding: 0.16rem 0.76rem;
          position: relative;
          overflow: hidden;
          .van-icon{
            position: absolute;
            font-size: .44rem;
            color: #fff;
            background: #3F9EFF;
            border-bottom-left-radius: 0.16rem;
            top: 0;
            right: -0.04rem;
          }
        }
      }
      .container-bottom{
        flex: 0 0 auto;
        height: 4rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .label-btn{
          width: 7.4rem;
          height: 1.6rem;
          background: #3F9EFF;
          border-radius: 0.8rem;
          line-height: 1.6rem;
          font-size: 0.64rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          margin: 0 auto;
        }
        .btn-disabled{
          opacity: 0.3;
        }
      }
    }
    .close-box{
      background: url("~@/assets/img/tj_close_icon.png") no-repeat;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: 2rem;
      background-size: 100% 100%;

    }
  }
}
</style>
